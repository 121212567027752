exports.conditionSnomed = [
  {
    "id": "abdominal_aortic_aneurysm",
    "snomed": [
      "233985008",
      "871536000",
      "735577004",
      "735576008",
      "715364001",
      "444569004",
      "426270006",
      "425771001",
      "315295003",
      "314186008",
      "314185007",
      "314184006",
      "233984007",
      "195268001",
      "195265003",
      "75878002",
      "14336007"
    ]
  },
  {
    "id": "thoracic_aortic_aneurysm",
    "snomed": [
      "433068007",
      "2831000119107",
      "735577004",
      "735575007",
      "712578006",
      "448746009",
      "448745008",
      "448744007",
      "448724001",
      "448723007",
      "448528000",
      "448328001",
      "448117008",
      "448116004",
      "448115000",
      "448100001",
      "448099009",
      "448098001",
      "445286001",
      "445285002",
      "445284003",
      "425963007",
      "253646008",
      "233984007",
      "195265003",
      "195258006",
      "74883004",
      "54160000"
    ]
  },
  {
    "id": "addiction_drug",
    "snomed": [
      "66214007",
      "11047881000119101",
      "429001000124103",
      "145841000119107",
      "145121000119106",
      "145101000119102",
      "125851000119106",
      "27051000119102",
      "1471000119103",
      "785277001",
      "773000004",
      "772999000",
      "762504005",
      "737336003",
      "724713006",
      "724712001",
      "724697004",
      "713775002",
      "699449003",
      "445273005",
      "441527004",
      "429692000",
      "427229002",
      "427205009",
      "426590003",
      "426095000",
      "425885002",
      "425533007",
      "414874007",
      "304605000",
      "280986007",
      "280985006",
      "280984005",
      "280983004",
      "280982009",
      "268648009",
      "268647004",
      "268646008",
      "248119008",
      "231462006",
      "231461004",
      "231459008",
      "231458000",
      "191938005",
      "191937000",
      "191936009",
      "191934007",
      "191932006",
      "191931004",
      "191930003",
      "191928000",
      "191925002",
      "191924003",
      "191920007",
      "191919001",
      "191918009",
      "191916008",
      "191914006",
      "191913000",
      "191912005",
      "191909007",
      "191907009",
      "191906000",
      "191905001",
      "191901005",
      "191900006",
      "191899001",
      "191895007",
      "191894006",
      "191893000",
      "191891003",
      "110281001",
      "91388009",
      "84758004",
      "78267003",
      "74851005",
      "70340006",
      "64386003",
      "49540005",
      "37344009",
      "26416006",
      "9769006",
      "7071007",
      "6525002",
      "5602001"
    ]
  },
  {
    "id": "addiction_alcohol",
    "snomed": [
      "7200002",
      "10755041000119100",
      "10741871000119101",
      "288031000119105",
      "714829008",
      "713862009",
      "713583005",
      "284591009",
      "268645007",
      "191884001",
      "191883007",
      "191882002",
      "191813001",
      "191812006",
      "191811004",
      "66590003",
      "15167005"
    ]
  },
  {
    "id": "addiction_tobacco",
    "snomed": [
      "89765005",
      "16077171000119107",
      "16077091000119107",
      "870621003",
      "191889006",
      "191888003",
      "191887008"
    ]
  },
  {
    "id": "adhd",
    "snomed": [
      "406506008",
      "702815001",
      "698692009",
      "698689005",
      "229715008",
      "192132008",
      "192131001",
      "192127007",
      "35253001",
      "31177006",
      "23148009",
      "7461003"
    ]
  },
  {
    "id": "adrenal_cortex_tumor",
    "snomed": [
      "127022002",
      "768470007",
      "371964008",
      "371963002",
      "302826002",
      "255035007",
      "94729000",
      "92526009",
      "91966003"
    ]
  },
  {
    "id": "alpha_1_antitrypsinase_deficiency",
    "snomed": [
      "30188007"
    ]
  },
  {
    "id": "alport_syndrome",
    "snomed": [
      "770414008",
      "726106004",
      "717768004",
      "717767009",
      "717766000"
    ]
  },
  {
    "id": "arrhythmogenic_right_ventricular_dysplasia",
    "snomed": [
      "253528005",
      "715865008",
      "715535009"
    ]
  },
  {
    "id": "asthma",
    "snomed": [
      "195967001",
      "16584951000119101",
      "10742121000119104",
      "10692761000119107",
      "10692721000119102",
      "10692681000119108",
      "10676511000119109",
      "10676431000119103",
      "10676391000119108",
      "10675991000119100",
      "10675911000119109",
      "10675871000119106",
      "10675751000119107",
      "10675551000119104",
      "10675471000119109",
      "10675431000119106",
      "10675391000119101",
      "10674991000119104",
      "10674711000119105",
      "2360001000004109",
      "135181000119109",
      "135171000119106",
      "125021000119107",
      "125011000119100",
      "125001000119103",
      "124991000119109",
      "103781000119103",
      "99031000119107",
      "72301000119103",
      "1751000119100",
      "1741000119102",
      "401000119107",
      "829976001",
      "786836003",
      "782520007",
      "782513000",
      "762521001",
      "735589002",
      "735588005",
      "734905008",
      "734904007",
      "733858005",
      "708096008",
      "708095007",
      "708094006",
      "708093000",
      "708090002",
      "708038006",
      "707981009",
      "707980005",
      "707979007",
      "707513007",
      "707512002",
      "707511009",
      "707447008",
      "707446004",
      "707445000",
      "707444001",
      "703954005",
      "703953004",
      "445427006",
      "442025000",
      "427679007",
      "427603009",
      "427295004",
      "426979002",
      "426656000",
      "425969006",
      "424643009",
      "424199006",
      "423889005",
      "418395004",
      "409663006",
      "407674008",
      "405944004",
      "404808000",
      "404806001",
      "404804003",
      "389145006",
      "370221004",
      "370220003",
      "370219009",
      "370218001",
      "304527002",
      "281239006",
      "266361008",
      "233688007",
      "233687002",
      "233683003",
      "233679003",
      "233678006",
      "225057002",
      "195977004",
      "195949008",
      "93432008",
      "92807009",
      "63088003",
      "59786004",
      "57607007",
      "56968009",
      "55570000",
      "41553006",
      "37981002",
      "34015007",
      "31387002",
      "19849005",
      "18041002",
      "12428000",
      "11641008"
    ]
  },
  {
    "id": "atrial_fibrillation",
    "snomed": [
      "49436004",
      "120041000119109",
      "715395008",
      "706923002",
      "440059007",
      "440028005",
      "426749004",
      "314208002",
      "300996004",
      "282825002",
      "233911009",
      "233910005",
      "195080001",
      "312442005"
    ]
  },
  {
    "id": "autism",
    "snomed": [
      "408856003",
      "722287002",
      "702732007",
      "408857007",
      "191690004",
      "191689008",
      "43614003"
    ]
  },
  {
    "id": "auto_immune_hepatitis",
    "snomed": [
      "408335007",
      "16098491000119109",
      "721713007",
      "721712002",
      "721711009",
      "19682006"
    ]
  },
  {
    "id": "bipolar",
    "snomed": [
      "13746004",
      "16238741000119105",
      "133091000119105",
      "23741000119105",
      "271000119101",
      "261000119107",
      "789061003",
      "767636002",
      "767635003",
      "767633005",
      "767632000",
      "767631007",
      "765176007",
      "723905008",
      "723903001",
      "371604007",
      "371600003",
      "371599001",
      "371596008",
      "231444002",
      "192362008",
      "191643001",
      "191641004",
      "191639000",
      "191638008",
      "191636007",
      "191634005",
      "191630001",
      "191629006",
      "191627008",
      "191625000",
      "191623007",
      "191621009",
      "191620005",
      "191618007",
      "111485001",
      "87950005",
      "87203005",
      "86058007",
      "85248005",
      "83225003",
      "82998009",
      "81319007",
      "79584002",
      "78640000",
      "78269000",
      "75752004",
      "75360000",
      "74686005",
      "73471000",
      "71984005",
      "71294008",
      "70546001",
      "68569003",
      "67002003",
      "66631006",
      "65042007",
      "64731001",
      "63249007",
      "61403008",
      "59617007",
      "55516002",
      "54761006",
      "53607008",
      "53049002",
      "51637008",
      "49512000",
      "49468007",
      "48937005",
      "46229002",
      "45479006",
      "43769008",
      "43568002",
      "41836007",
      "41832009",
      "41552001",
      "40926005",
      "38368003",
      "36583000",
      "35846004",
      "35722002",
      "35481005",
      "34315001",
      "33380008",
      "31446002",
      "30935000",
      "30687003",
      "30520009",
      "29929003",
      "28884001",
      "28663008",
      "26530004",
      "26203008",
      "22407005",
      "22121000",
      "21900002",
      "20960007",
      "19300006",
      "17782008",
      "16506000",
      "16295005",
      "14495005",
      "13581000",
      "13313007",
      "12969000",
      "10981006",
      "10875004",
      "9340000",
      "5703000",
      "4441000",
      "3530005",
      "1499003",
      "1196001",
      "162004"
    ]
  },
  {
    "id": "birt_hogg_dube_syndrome",
    "snomed": [
      "110985001"
    ]
  },
  {
    "id": "blindness",
    "snomed": [
      "193699007",
      "765178008",
      "193708001",
      "193707006",
      "193704004",
      "193703005"
    ]
  },
  {
    "id": "bone_cancer",
    "snomed": [
      "93725000",
      "354681000119109",
      "354671000119106",
      "354651000119102",
      "354641000119104",
      "354621000119105",
      "353671000119108",
      "353661000119102",
      "91081000119109",
      "91061000119100",
      "91041000119104",
      "91031000119108",
      "78411000119107",
      "870318006",
      "735679005",
      "723887001",
      "723853006",
      "723850009",
      "723848001",
      "723846002",
      "723843005",
      "713290004",
      "713038003",
      "710196003",
      "710195004",
      "698200003",
      "447730004",
      "446939001",
      "440397000",
      "433067002",
      "372133009",
      "372121004",
      "372116002",
      "372115003",
      "372107001",
      "372021001",
      "372008008",
      "307609003",
      "307608006",
      "307576001",
      "286890004",
      "286889008",
      "281702006",
      "255068000",
      "134312002",
      "109348004",
      "94145009",
      "94142007",
      "94112009",
      "94107008",
      "94099002",
      "94094007",
      "94085001",
      "94084002",
      "94083008",
      "94073009",
      "94066004",
      "94004004",
      "94003005",
      "94001007",
      "93997008",
      "93996004",
      "93990005",
      "93979000",
      "93975006",
      "93963001",
      "93960003",
      "93959008",
      "93951006",
      "93950007",
      "93945001",
      "93936002",
      "93927001",
      "93920004",
      "93916003",
      "93893006",
      "93892001",
      "93888008",
      "93887003",
      "93886007",
      "93879004",
      "93872008",
      "93871001",
      "93842002",
      "93833009",
      "93820003",
      "93806005",
      "93800004",
      "93799003",
      "93798006",
      "93786001",
      "93769007",
      "93760006",
      "93757004",
      "93741004",
      "93737003",
      "93724001",
      "93723007",
      "93722002",
      "93721009",
      "93661001",
      "429247002",
      "139621000119101",
      "429023001",
      "428052005"
    ]
  },
  {
    "id": "brain_cancer",
    "snomed": [
      "254938000",
      "107581000119103",
      "100731000119107",
      "100721000119109",
      "99131000119108",
      "67771000119102",
      "126952004",
      "684911000119105",
      "104981000119104",
      "90831000119105",
      "90811000119100",
      "87121000119102",
      "87111000119109",
      "87091000119101",
      "11471000224106",
      "830033003",
      "830032008",
      "816205008",
      "788758003",
      "788757008",
      "783008000",
      "780822000",
      "770682007",
      "762457009",
      "735757008",
      "721535002",
      "716592003",
      "699331002",
      "699318007",
      "608817003",
      "449799008",
      "449420002",
      "449253005",
      "448989001",
      "448863000",
      "448563005",
      "448250003",
      "448248006",
      "448218008",
      "448148000",
      "448135004",
      "444545003",
      "429765002",
      "429565004",
      "429408002",
      "429033009",
      "428965004",
      "428964000",
      "428960009",
      "428753007",
      "428061005",
      "425868004",
      "424276002",
      "424151006",
      "363483004",
      "363482009",
      "363473003",
      "363471001",
      "363470000",
      "363469001",
      "363468009",
      "363467004",
      "359619007",
      "285641009",
      "281560004",
      "277508009",
      "277507004",
      "277505007",
      "277461004",
      "277456001",
      "276828006",
      "276826005",
      "275269004",
      "271479005",
      "255200003",
      "255112006",
      "254970000",
      "254969001",
      "254968009",
      "254955001",
      "254945000",
      "254943007",
      "254942002",
      "254941009",
      "254940005",
      "254939008",
      "254936001",
      "237721006",
      "189488006",
      "189487001",
      "189179009",
      "189162003",
      "188462001",
      "188340000",
      "188339002",
      "188308004",
      "188302003",
      "188301005",
      "188298006",
      "188297001",
      "188296005",
      "188295009",
      "188293002",
      "188292007",
      "188290004",
      "188289008",
      "188287005",
      "188286001",
      "188285002",
      "188283009",
      "188282004",
      "188281006",
      "188280007",
      "135884009",
      "127026004",
      "127025000",
      "127024001",
      "126961004",
      "126960003",
      "126958000",
      "126957005",
      "126956001",
      "126955002",
      "126954003",
      "126953009",
      "126948004",
      "126947009",
      "109912006",
      "95126008",
      "95004009",
      "95002008",
      "94986003",
      "94968001",
      "94847003",
      "94808000",
      "94789003",
      "94788006",
      "94786005",
      "94767002",
      "94766006",
      "94622002",
      "94491007",
      "94489004",
      "94471000",
      "94448002",
      "94309003",
      "94267001",
      "94248000",
      "94247005",
      "94245002",
      "94225005",
      "94224009",
      "94086000",
      "93964007",
      "93962006",
      "93946000",
      "93928006",
      "93807001",
      "93768004",
      "93749002",
      "93748005",
      "93746009",
      "93727008",
      "93726004",
      "92683008",
      "92682003",
      "92573003",
      "92427003",
      "92296004",
      "92294001",
      "92276007",
      "92253009",
      "92114009",
      "92072003",
      "92052008",
      "92050000",
      "92030004",
      "92029009",
      "416901002"
    ]
  },
  {
    "id": "breast_cancer",
    "snomed": [
      "254838004",
      "1080241000119104",
      "1080231000119108",
      "1080211000119103",
      "1080201000119101",
      "1080191000119104",
      "1080171000119100",
      "1080161000119106",
      "1080151000119109",
      "1080131000119103",
      "1080121000119101",
      "1080111000119108",
      "1080091000119100",
      "96291000119105",
      "865954003",
      "767444009",
      "763479005",
      "716593008",
      "713609000",
      "708921005",
      "706970001",
      "448952004",
      "447782002",
      "444712000",
      "444604002",
      "431396003",
      "427685000",
      "408643008",
      "403458008",
      "372096000",
      "286897001",
      "286896005",
      "286895009",
      "286894008",
      "286893002",
      "255068000",
      "254841008",
      "254840009",
      "254839007",
      "429087003",
      "143351000119104",
      "48901000119103",
      "473062003",
      "415076002",
      "109889007",
      "88811000119104",
      "109888004",
      "354471000119108",
      "354341000119108",
      "762260000",
      "143481000119103",
      "278054005",
      "1080341000119105",
      "1080261000119100",
      "722524005"
    ]
  },
  {
    "id": "brugada",
    "snomed": [
      "418818005"
    ]
  },
  {
    "id": "carcinoid_tumor",
    "snomed": [
      "443492008",
      "351171000119101",
      "123201000119107",
      "734884008",
      "726654006",
      "726653000",
      "726652005",
      "714811003",
      "714810002",
      "713574000",
      "713573006",
      "713306000",
      "709830006",
      "709517003",
      "705176003",
      "445238008",
      "428701004",
      "425318003",
      "314947008",
      "276819005",
      "276818002",
      "276816003",
      "276808007",
      "254627002",
      "254613007",
      "253003009",
      "253002004",
      "237833006",
      "35868009",
      "59651000119104",
      "286771000119106",
      "286791000119107",
      "286781000119109",
      "10987961000119105"
    ]
  },
  {
    "id": "carotid_artery_stenosis",
    "snomed": [
      "69798007",
      "285171000119104",
      "285161000119105",
      "703184001",
      "703180005",
      "426651005",
      "266254007",
      "64586002",
      "285201000119100",
      "285191000119103",
      "99451000119105",
      "9611000119107",
      "787044009",
      "233964008",
      "93396008",
      "789642001"
    ]
  },
  {
    "id": "catecholaminergic_polymorphic_ventricular_tachycardia",
    "snomed": [
      "419671004"
    ]
  },
  {
    "id": "colon_cancer",
    "snomed": [
      "363406005",
      "681651000119102",
      "681601000119101",
      "1701000119104",
      "737058005",
      "721699002",
      "721696009",
      "721695008",
      "363413005",
      "363412000",
      "363410008",
      "363409003",
      "363408006",
      "363407001",
      "314965007",
      "312115000",
      "312114001",
      "312113007",
      "312112002",
      "312111009",
      "301756000",
      "285312008",
      "269533000",
      "187757001",
      "109838007",
      "94105000",
      "94072004",
      "94006002",
      "93826009",
      "93771007",
      "93761005",
      "93683002",
      "429699009",
      "93854002",
      "721672004",
      "371977004",
      "255081007",
      "109840002",
      "93679002"
    ]
  },
  {
    "id": "colon_polyp",
    "snomed": [
      "68496003",
      "1085391000119106",
      "712979009",
      "712731007",
      "712730008",
      "712728006",
      "711368009",
      "698019000",
      "449855005",
      "428472008",
      "428054006",
      "13025001",
      "429047008"
    ]
  },
  {
    "id": "copd_chronic_bronchitis_emphysema",
    "snomed": [
      "13645005",
      "10692761000119107",
      "106001000119101",
      "1751000119100",
      "840351007",
      "840350008",
      "836477007",
      "708030004",
      "313299006",
      "313297008",
      "313296004",
      "285381006",
      "266356006",
      "266355005",
      "233677001",
      "233675009",
      "233674008",
      "196026004",
      "196001008",
      "195959009",
      "195958001",
      "195957006",
      "195951007",
      "135836000",
      "87433001",
      "86680006",
      "77690003",
      "70756004",
      "68328006",
      "66987001",
      "60805002",
      "57686001",
      "47938003",
      "47895001",
      "33325001",
      "31898008",
      "23958009",
      "16846004",
      "16003001",
      "4981000",
      "270473001"
    ]
  },
  {
    "id": "cowden_syndrome",
    "snomed": [
      "58037000"
    ]
  },
  {
    "id": "crohns_disease",
    "snomed": [
      "34000006",
      "10743231000119101",
      "1085901000119101",
      "1085851000119105",
      "1085801000119106",
      "1085751000119100",
      "8161000119106",
      "737195007",
      "733157003",
      "722850002",
      "721702009",
      "426549001",
      "414154002",
      "414153008",
      "397173003",
      "397172008",
      "235664007",
      "235607002",
      "234999001",
      "196977009",
      "196578009",
      "91390005",
      "71833008",
      "70622003",
      "61424003",
      "56689002",
      "56287005",
      "50440006",
      "38106008",
      "7620006",
      "3815005",
      "112561000119108"
    ]
  },
  {
    "id": "cystic_kidney_disease",
    "snomed": [
      "722223000",
      "369071000119105",
      "98371000119102",
      "5941000119101",
      "773737004",
      "763891005",
      "737562008",
      "735471001",
      "724094005",
      "723999009",
      "721862000",
      "717749002",
      "717748005",
      "717746009",
      "717187000",
      "447335007",
      "446991001",
      "446989009",
      "444830001",
      "444749006",
      "444558002",
      "373599008",
      "270910001",
      "268234004",
      "253885004",
      "253884000",
      "253883006",
      "253882001",
      "253881008",
      "236614007",
      "236531005",
      "236443009",
      "236442004",
      "236441006",
      "204958008",
      "204957003",
      "197802007",
      "197801000",
      "187144000",
      "105999006",
      "86463003",
      "82525005",
      "77945009",
      "71064009",
      "57088004",
      "28770003"
    ]
  },
  {
    "id": "dvt",
    "snomed": [
      "128053003",
      "15760351000119105",
      "15711401000119105",
      "15711241000119106",
      "15708401000119100",
      "15708201000119101",
      "293491000119107",
      "293481000119109",
      "293461000119100",
      "293451000119102",
      "285381000119104",
      "143561000119108",
      "143551000119106",
      "136781000119101",
      "136771000119104",
      "136751000119108",
      "135011000119102",
      "135001000119100",
      "134961000119104",
      "133421000119101",
      "133411000119108",
      "132611000119104",
      "132551000119104",
      "132321000119103",
      "132301000119107",
      "132291000119106",
      "132281000119108",
      "132271000119105",
      "132261000119104",
      "132251000119101",
      "132241000119103",
      "132221000119109",
      "132201000119100",
      "132191000119103",
      "132171000119104",
      "132161000119105",
      "132151000119108",
      "132141000119106",
      "132131000119102",
      "132111000119107",
      "132091000119104",
      "651000119108",
      "860699005",
      "840713005",
      "762256003",
      "710167004",
      "709687000",
      "703277001",
      "444325005",
      "443210003",
      "438785004",
      "438647008",
      "438646004",
      "438483005",
      "428781001",
      "427776007",
      "427775006",
      "404223003",
      "371051005",
      "297156001",
      "280966008",
      "266267005",
      "234044007",
      "234041004",
      "213220000",
      "200238005",
      "200237000",
      "200233001",
      "200232006",
      "197001004",
      "195427008",
      "195426004",
      "195425000",
      "195414009",
      "195412008",
      "195411001",
      "195410000",
      "134399007",
      "128055005",
      "128054009",
      "95452006",
      "95447001",
      "95446005",
      "66923004",
      "66877004",
      "62583006",
      "56272000",
      "52496006",
      "49956009",
      "42861008",
      "40198004",
      "16750002",
      "14534009",
      "7387004",
      "1748006",
      "161508001",
      "141911000119100",
      "307091009",
      "307116001",
      "307115002",
      "440989002",
      "441421001",
      "441420000",
      "36351005",
      "439699000",
      "1563006",
      "441189007",
      "440988005",
      "439702007",
      "76407009",
      "441188004",
      "441101007",
      "439274008",
      "402851000"
    ]
  },
  {
    "id": "dementia",
    "snomed": [
      "52448006",
      "16276361000119109",
      "16219201000119101",
      "288631000119104",
      "142011000119109",
      "142001000119106",
      "141991000119109",
      "130121000119104",
      "105421000119105",
      "101421000119107",
      "97751000119108",
      "82371000119101",
      "82361000119107",
      "79341000119107",
      "31081000119101",
      "22381000119105",
      "21921000119103",
      "2421000119107",
      "1591000119103",
      "1581000119101",
      "840465008",
      "840464007",
      "838276009",
      "833326008",
      "788899002",
      "788898005",
      "762707000",
      "762351006",
      "762350007",
      "733194007",
      "733193001",
      "733192006",
      "733191004",
      "733190003",
      "733185001",
      "733184002",
      "725898002",
      "724777003",
      "724776007",
      "723390000",
      "723123001",
      "722980006",
      "722979008",
      "722978000",
      "722977005",
      "722600006",
      "715737004",
      "713844000",
      "713488003",
      "698955006",
      "698954005",
      "698949001",
      "698948009",
      "698781002",
      "698726009",
      "698725008",
      "698687007",
      "698626001",
      "698625002",
      "698624003",
      "442344002",
      "429998004",
      "429458009",
      "425390006",
      "421529006",
      "421023003",
      "420614009",
      "416975007",
      "416780008",
      "371026009",
      "371024007",
      "312991009",
      "278857002",
      "268612007",
      "230289009",
      "230287006",
      "230286002",
      "230285003",
      "230283005",
      "230282000",
      "230280008",
      "230269008",
      "230268000",
      "230267005",
      "230266001",
      "230265002",
      "191519005",
      "191493005",
      "191466007",
      "191465006",
      "191464005",
      "191463004",
      "191461002",
      "191459006",
      "191458003",
      "191457008",
      "191455000",
      "191454001",
      "191452002",
      "191451009",
      "191449005",
      "111480006",
      "90099008",
      "82959004",
      "66108005",
      "65096006",
      "59651006",
      "55009008",
      "54502004",
      "51928006",
      "40425004",
      "32875003",
      "26929004",
      "26852004",
      "15662003",
      "12348006",
      "10532003",
      "9345005",
      "6475002",
      "4817008",
      "281004",
      "161465002"
    ]
  },
  {
    "id": "depression",
    "snomed": [
      "35489007",
      "16266991000119108",
      "16266831000119100",
      "16265951000119109",
      "16265301000119106",
      "16265061000119105",
      "16264901000119109",
      "16264821000119108",
      "16264621000119109",
      "16238221000119109",
      "16238181000119101",
      "10835871000119104",
      "10811161000119107",
      "10811121000119102",
      "288751000119101",
      "133121000119109",
      "104851000119103",
      "94631000119100",
      "10211000132109",
      "281000119103",
      "251000119105",
      "838530009",
      "788120007",
      "762512002",
      "762345001",
      "762339009",
      "762336002",
      "762329003",
      "762321000",
      "726772006",
      "724690002",
      "724678004",
      "724677009",
      "724676000",
      "723930006",
      "723928009",
      "720455008",
      "720454007",
      "720453001",
      "720452006",
      "720451004",
      "719593009",
      "719592004",
      "718636001",
      "712823008",
      "698957003",
      "450714000",
      "442057004",
      "430852001",
      "426578000",
      "370143000",
      "321717001",
      "320751009",
      "319768000",
      "310497006",
      "310496002",
      "310495003",
      "300706003",
      "279225001",
      "274948002",
      "268621008",
      "247803002",
      "237350002",
      "237349002",
      "231542000",
      "231504006",
      "231500002",
      "231499006",
      "231485007",
      "192080009",
      "192079006",
      "191676002",
      "191659001",
      "191616006",
      "191613003",
      "191611001",
      "191610000",
      "191604000",
      "191495003",
      "162722001",
      "87842000",
      "87512008",
      "87414006",
      "85080004",
      "84788008",
      "84760002",
      "83458005",
      "83176005",
      "82218004",
      "79842004",
      "79298009",
      "78667006",
      "77911002",
      "76441001",
      "75084000",
      "73867007",
      "71336009",
      "70747007",
      "69392006",
      "68019004",
      "67711008",
      "66344007",
      "63778009",
      "63412003",
      "60099002",
      "58703003",
      "48589009",
      "46244001",
      "42925002",
      "42810003",
      "40568001",
      "40379007",
      "39809009",
      "38694004",
      "38451003",
      "36923009",
      "36474008",
      "36170009",
      "33736005",
      "33135002",
      "33078009",
      "30605009",
      "28475009",
      "25922000",
      "20250007",
      "19694002",
      "19527009",
      "18818009",
      "15639000",
      "15193003",
      "14183003",
      "3109008",
      "2618002",
      "2506003",
      "832007",
      "596004",
      "161469008",
      "1099001000119103"
    ]
  },
  {
    "id": "diabetes_gestational",
    "snomed": [
      "11687002",
      "10753491000119101",
      "40801000119106",
      "40791000119105",
      "75022004",
      "46894009",
      "472971004"
    ]
  },
  {
    "id": "diabetes_type_1",
    "snomed": [
      "46635009",
      "368521000119107",
      "31321000119102",
      "703137001",
      "609566000",
      "609564002",
      "426875007",
      "313435000",
      "199229001",
      "190372001",
      "190368000",
      "28032008",
      "23045005",
      "472970003"
    ]
  },
  {
    "id": "diabetes_type_2",
    "snomed": [
      "44054006",
      "1481000119100",
      "703138006",
      "609567009",
      "359642000",
      "313436004",
      "237627000",
      "237599002",
      "199230006",
      "190389009",
      "81531005",
      "472969004"
    ]
  },
  {
    "id": "diabetic_kidney_disease",
    "snomed": [
      "127013003",
      "243421000119104",
      "157141000119108",
      "140131000119102",
      "140121000119100",
      "140111000119107",
      "140101000119109",
      "96441000119101",
      "90791000119104",
      "90781000119102",
      "90771000119100",
      "90761000119106",
      "90751000119109",
      "90741000119107",
      "90731000119103",
      "90721000119101",
      "71721000119101",
      "71701000119105",
      "71441000119104",
      "71421000119105",
      "18521000119106",
      "771000119108",
      "751000119104",
      "741000119101",
      "731000119105",
      "721000119107",
      "711000119100",
      "792926007",
      "723074006",
      "707221002",
      "445170001",
      "425455002",
      "421986006",
      "421893009",
      "421305000",
      "420715001",
      "420514000",
      "420279001",
      "311366001",
      "310387003",
      "309426007",
      "236500003",
      "236499007",
      "197605007",
      "63510008",
      "38046004"
    ]
  },
  {
    "id": "dilated_cardiomyopathy",
    "snomed": [
      "399020009",
      "111000119104",
      "871639003",
      "766883006",
      "725416005",
      "723993005",
      "721014007",
      "720609003",
      "719839000",
      "719835006",
      "719824001",
      "719451006",
      "719380003",
      "702424003",
      "699668009",
      "472327008",
      "472100003",
      "471890009",
      "426856002",
      "413995009",
      "413994008",
      "233871002",
      "195021004",
      "111285003",
      "91604001",
      "90202001",
      "86995005",
      "86194008",
      "84733009",
      "83521008",
      "79152002",
      "74249003",
      "72972005",
      "64749001",
      "60514000",
      "58911001",
      "58629009",
      "54830008",
      "53043001",
      "52029003",
      "50270004",
      "46696008",
      "44630002",
      "40532006",
      "39693003",
      "36471000",
      "35719004",
      "34342000",
      "30496006",
      "28869005",
      "21430002",
      "20529002",
      "16253001",
      "12563008",
      "11104006",
      "8209004",
      "6022005",
      "2816000",
      "2024009",
      "1954004"
    ]
  },
  {
    "id": "eating_disorder",
    "snomed": [
      "72366004",
      "870568002",
      "723920002",
      "723919008",
      "723918000",
      "723917005",
      "722960005",
      "722959000",
      "722877006",
      "705001007",
      "700213005",
      "698698008",
      "698695006",
      "439960005",
      "427469003",
      "426881004",
      "275474009",
      "270902002",
      "249520001",
      "248118000",
      "231523004",
      "231522009",
      "231521002",
      "192631000",
      "192017004",
      "192016008",
      "78004001",
      "77675002",
      "74142004",
      "63393005",
      "59645001",
      "56882008",
      "37941009",
      "36039004",
      "32721004",
      "14077003",
      "126131000119101",
      "126151000119107",
      "705002000",
      "161471008"
    ]
  },
  {
    "id": "ehlers_danlos_syndrome",
    "snomed": [
      "398114001",
      "778022009",
      "773276004",
      "733457006",
      "720861000",
      "720860004",
      "720859009",
      "720858001",
      "719096006",
      "718211004",
      "715318006",
      "709604005",
      "86667008",
      "83586000",
      "83470009",
      "71322004",
      "70610001",
      "67202007",
      "55711009",
      "50869007",
      "31798004",
      "30652003",
      "25606004",
      "20766005",
      "17025000",
      "10033001",
      "4170004"
    ]
  },
  {
    "id": "esophageal_cancer",
    "snomed": [
      "371984007",
      "128041000119107",
      "783704005",
      "783183009",
      "722533007",
      "721628002",
      "721624000",
      "721623006",
      "721622001",
      "721621008",
      "721620009",
      "721619003",
      "721618006",
      "721617001",
      "372023003",
      "372017008",
      "371999007",
      "371998004",
      "371978009",
      "371962007",
      "254551004",
      "254549003",
      "254547001",
      "254543002",
      "254539001",
      "254535007",
      "109835005",
      "429410000",
      "255078002"
    ]
  },
  {
    "id": "eczema",
    "snomed": [
      "43116000",
      "13201000132100",
      "5391000119108",
      "840284009",
      "788499005",
      "784292007",
      "780843004",
      "762389001",
      "737172003",
      "726476005",
      "725148000",
      "724871008",
      "724511006",
      "724465009",
      "723003004",
      "721212001",
      "721180007",
      "721171007",
      "720493003",
      "718215008",
      "718068005",
      "717259002",
      "717258005",
      "717257000",
      "717061002",
      "717055000",
      "716790008",
      "707250009",
      "707249009",
      "707248001",
      "707247006",
      "442145005",
      "427348001",
      "419735006",
      "414492009",
      "403613008",
      "403612003",
      "403325003",
      "403178009",
      "402588008",
      "402586007",
      "402585006",
      "402572008",
      "402569001",
      "402468007",
      "402349005",
      "402348002",
      "402303001",
      "402302006",
      "402301004",
      "402299006",
      "402295000",
      "402292002",
      "402291009",
      "402273004",
      "402263001",
      "402243008",
      "402242003",
      "402241005",
      "402240006",
      "402239009",
      "402238001",
      "402234004",
      "402233005",
      "402232000",
      "402230008",
      "402229003",
      "402228006",
      "402227001",
      "402226005",
      "402224008",
      "402223002",
      "402222007",
      "402221000",
      "402220004",
      "402219005",
      "402218002",
      "402216003",
      "402215004",
      "402214000",
      "402213006",
      "402201001",
      "402200000",
      "402199003",
      "402198006",
      "402197001",
      "402196005",
      "402195009",
      "402193002",
      "402192007",
      "402191000",
      "402188000",
      "402187005",
      "402186001",
      "402185002",
      "402183009",
      "400108007",
      "400079002",
      "400051008",
      "400005007",
      "398970001",
      "396348002",
      "373601005",
      "309461001",
      "300926005",
      "300924008",
      "281104002",
      "275889007",
      "238991008",
      "238990009",
      "238782003",
      "238672005",
      "238671003",
      "238670002",
      "238669003",
      "238668006",
      "238667001",
      "238666005",
      "238665009",
      "238664008",
      "238663002",
      "238662007",
      "238661000",
      "238660004",
      "238659009",
      "238658001",
      "238657006",
      "238656002",
      "238655003",
      "238654004",
      "238653005",
      "238652000",
      "238651007",
      "238649008",
      "238648000",
      "238647005",
      "238646001",
      "238645002",
      "238597002",
      "238594009",
      "238593003",
      "238591001",
      "238585003",
      "238564003",
      "238562004",
      "238561006",
      "238560007",
      "238559002",
      "238558005",
      "238557000",
      "238554007",
      "238553001",
      "238552006",
      "238551004",
      "238549003",
      "238548006",
      "238547001",
      "238546005",
      "238545009",
      "238544008",
      "238543002",
      "238542007",
      "238541000",
      "238539001",
      "238473008",
      "238472003",
      "237463009",
      "237112004",
      "235049008",
      "232242004",
      "201201000",
      "201077008",
      "201002003",
      "201001005",
      "201000006",
      "200999007",
      "200775004",
      "200773006",
      "135843006",
      "135841008",
      "123704008",
      "123703002",
      "123701000",
      "123700004",
      "123699000",
      "123698008",
      "111197009",
      "111188005",
      "95812002",
      "95809000",
      "91178003",
      "90823000",
      "88996004",
      "84855007",
      "84479000",
      "81418003",
      "74207008",
      "68266006",
      "64540004",
      "57092006",
      "54272002",
      "53891004",
      "50650004",
      "44509000",
      "44256003",
      "42442001",
      "41890004",
      "41580004",
      "39422006",
      "27620007",
      "25858008",
      "24079001",
      "23615008",
      "20343006",
      "16865007",
      "11011007",
      "9768003",
      "6111009",
      "4859009",
      "4776004",
      "3358001",
      "978003"
    ]
  },
  {
    "id": "familial_adenomatous_polyposis",
    "snomed": [
      "72900001",
      "763536006",
      "715866009",
      "61665008",
      "60876000"
    ]
  },
  {
    "id": "familial_hypercholesterolemia",
    "snomed": [
      "398036000",
      "767133009",
      "403831006",
      "403830007",
      "403829002",
      "397915002",
      "238079002",
      "238078005"
    ]
  },
  {
    "id": "gastric_surgery",
    "snomed": [
      "698450007",
      "18692006",
      "708629005",
      "443906008",
      "433024005",
      "359529008",
      "358575006",
      "314593003",
      "287834004",
      "265365007",
      "235282003",
      "235281005",
      "173857001",
      "173751007",
      "173750008",
      "173748000",
      "173747005",
      "49245001",
      "45753003",
      "30803004",
      "708983005"
    ]
  },
  {
    "id": "glaucoma",
    "snomed": [
      "23986001",
      "15993751000119108",
      "15993711000119107",
      "15993671000119108",
      "15739881000119108",
      "15739801000119100",
      "15738761000119100",
      "15736721000119106",
      "15679801000119105",
      "15673041000119101",
      "678481000119105",
      "678471000119107",
      "678421000119106",
      "678411000119104",
      "678401000119102",
      "349311000119106",
      "348811000119108",
      "348361000119103",
      "347401000119106",
      "347381000119106",
      "346861000119107",
      "345291000119109",
      "344491000119107",
      "344251000119103",
      "343161000119109",
      "342241000119105",
      "342221000119104",
      "341641000119100",
      "339921000119104",
      "338761000119103",
      "338721000119108",
      "338481000119100",
      "337081000119109",
      "336631000119104",
      "336611000119109",
      "336031000119105",
      "334321000119101",
      "333151000119108",
      "333111000119107",
      "332871000119103",
      "331471000119105",
      "41911000119107",
      "24151000119106",
      "860798008",
      "833278008",
      "788995007",
      "788948008",
      "788947003",
      "788946007",
      "788945006",
      "787052007",
      "787051000",
      "783246000",
      "770777006",
      "770776002",
      "737006004",
      "736838001",
      "723503006",
      "722329004",
      "722321001",
      "721898008",
      "719976001",
      "715144004",
      "713457002",
      "698840003",
      "444863008",
      "415176004",
      "404648005",
      "404634005",
      "392352004",
      "392300000",
      "392291006",
      "392288006",
      "392030001",
      "392029006",
      "370504007",
      "314784002",
      "314033007",
      "314017009",
      "302895007",
      "275477002",
      "267625001",
      "232090003",
      "232088004",
      "232087009",
      "232086000",
      "232083008",
      "232082003",
      "232081005",
      "232080006",
      "206248004",
      "204113001",
      "193562004",
      "193561006",
      "193556009",
      "193555008",
      "193553001",
      "193552006",
      "193548006",
      "193534006",
      "193533000",
      "193531003",
      "111514006",
      "111513000",
      "95717004",
      "95250000",
      "95213001",
      "93435005",
      "92829008",
      "89215000",
      "84494001",
      "84333006",
      "77075001",
      "71111008",
      "68241007",
      "66747002",
      "66725002",
      "65460003",
      "53667005",
      "50485007",
      "46168003",
      "45623002",
      "37155002",
      "35472004",
      "34623005",
      "33647009",
      "32893002",
      "30041005",
      "29538005",
      "29369005",
      "27735002",
      "21928008",
      "21571006",
      "19309007",
      "19144002",
      "15374009",
      "1654001",
      "1207009",
      "161488001"
    ]
  },
  {
    "id": "heart_attack_coronary_artery_disease",
    "snomed": [
      "53741008",
      "16891151000119103",
      "15960661000119107",
      "15960581000119102",
      "15960381000119109",
      "15960141000119102",
      "15960061000119102",
      "11018701000119109",
      "285151000119108",
      "285141000119106",
      "139011000119104",
      "791000119109",
      "724431008",
      "723862008",
      "719678003",
      "444856008",
      "444855007",
      "443502000",
      "442421004",
      "442240008",
      "442224005",
      "429673002",
      "429245005",
      "427919004",
      "420006002",
      "371805005",
      "371804009",
      "371803003",
      "315348000",
      "233844002",
      "233817007",
      "194843003",
      "194842008",
      "92517006",
      "42866003",
      "414545008",
      "16837681000119104",
      "16754391000119100",
      "15960541000119107",
      "15713201000119105",
      "15713161000119100",
      "15713121000119105",
      "15713081000119108",
      "15713041000119103",
      "15712961000119108",
      "15712921000119103",
      "15712881000119105",
      "15712841000119100",
      "12238151000119107",
      "12238111000119106",
      "285981000119103",
      "23311000119105",
      "17531000119105",
      "10971000087107",
      "868226001",
      "868225002",
      "868224003",
      "868220007",
      "868217004",
      "868214006",
      "846683001",
      "846668006",
      "840680009",
      "840609007",
      "840316004",
      "840312002",
      "840309000",
      "836295007",
      "836294006",
      "836293000",
      "713405002",
      "712866001",
      "703360004",
      "703253007",
      "703252002",
      "703251009",
      "703214003",
      "703213009",
      "703212004",
      "703211006",
      "703210007",
      "703209002",
      "703165004",
      "703164000",
      "697976003",
      "429559004",
      "428196007",
      "418044006",
      "414795007",
      "413844008",
      "413838009",
      "413444003",
      "413439005",
      "401314000",
      "401303003",
      "394710008",
      "394659003",
      "371812001",
      "371811008",
      "371810009",
      "371809004",
      "371808007",
      "371807002",
      "371806006",
      "315025001",
      "314207007",
      "314116003",
      "311796008",
      "311793000",
      "311792005",
      "307140009",
      "304914007",
      "300995000",
      "276517000",
      "276516009",
      "233843008",
      "233842003",
      "233841005",
      "233840006",
      "233839009",
      "233838001",
      "233837006",
      "233836002",
      "233835003",
      "233834004",
      "233833005",
      "233832000",
      "233831007",
      "233830008",
      "233829003",
      "233828006",
      "233827001",
      "233826005",
      "233825009",
      "233823002",
      "233821000",
      "233819005",
      "194858006",
      "194857001",
      "194856005",
      "194828000",
      "194823009",
      "194809007",
      "194802003",
      "129574000",
      "89323001",
      "87343002",
      "85284003",
      "82522008",
      "79009004",
      "76593002",
      "73795002",
      "70998009",
      "70422006",
      "70211005",
      "65547006",
      "64627002",
      "62695002",
      "61490001",
      "59063002",
      "59021001",
      "58612006",
      "57054005",
      "54329005",
      "52035003",
      "46109009",
      "42531007",
      "41334000",
      "35928006",
      "32574007",
      "25106000",
      "22298006",
      "21470009",
      "19057007",
      "15990001",
      "10273003",
      "4557003",
      "1755008",
      "282006",
      "428375006",
      "130541000119100",
      "428912006",
      "428664003"
    ]
  },
  {
    "id": "hemorrhagic_stroke",
    "snomed": [
      "230706003",
      "384993003",
      "230708002",
      "230707007",
      "141811000119106",
      "118961000119101"
    ]
  },
  {
    "id": "hereditary_breast_and_ovarian_cancer_syndrome",
    "snomed": [
      "254843006",
      "718220008",
      "445333001",
      "412738007",
      "412734009"
    ]
  },
  {
    "id": "hereditary_diffuse_gastric_cancer",
    "snomed": [
      "716859000"
    ]
  },
  {
    "id": "hereditary_hemochromatosis",
    "snomed": [
      "35400008",
      "719975002",
      "719974003"
    ]
  },
  {
    "id": "hereditary_leiomyomatosis_and_renal_cell_carcinoma_syndrome",
    "snomed": []
  },
  {
    "id": "hereditary_melanoma",
    "snomed": []
  },
  {
    "id": "hereditary_papillary_renal_cancer_syndrome",
    "snomed": [
      "715561008"
    ]
  },
  {
    "id": "hereditary_paraganglioma_pheochromocytoma_syndrome",
    "snomed": [
      "716857003"
    ]
  },
  {
    "id": "hereditary_retinoblastoma",
    "snomed": []
  },
  {
    "id": "high_cholesterol",
    "snomed": [
      "55822004",
      "137941000119106",
      "137931000119102",
      "114831000119107",
      "15771000119109",
      "1571000119104",
      "701000119103",
      "773726000",
      "773649005",
      "445261005",
      "426161002",
      "403828005",
      "403827000",
      "402787000",
      "402786009",
      "402785008",
      "402727002",
      "402726006",
      "402725005",
      "402475008",
      "402474007",
      "402473001",
      "302870006",
      "299465007",
      "267435002",
      "267434003",
      "267433009",
      "267432004",
      "238089003",
      "238088006",
      "238087001",
      "238085009",
      "238084008",
      "238083002",
      "238082007",
      "238081000",
      "238080004",
      "238077000",
      "238076009",
      "238040008",
      "190774002",
      "129591001",
      "129590000",
      "129589009",
      "34528009",
      "34349009",
      "33513003",
      "13644009",
      "414416008"
    ]
  },
  {
    "id": "hypertension",
    "snomed": [
      "38341003",
      "16229371000119106",
      "10757401000119104",
      "10752641000119102",
      "1078301000112109",
      "140131000119102",
      "140121000119100",
      "140111000119107",
      "140101000119109",
      "132721000119104",
      "118781000119108",
      "82771000119102",
      "71701000119105",
      "71421000119105",
      "40521000119100",
      "40511000119107",
      "5501000119106",
      "541000119105",
      "871642009",
      "765182005",
      "762463000",
      "712832005",
      "706882009",
      "698640000",
      "698638005",
      "697930002",
      "697929007",
      "443482000",
      "429457004",
      "429198000",
      "428575007",
      "427889009",
      "398254007",
      "397748008",
      "371125006",
      "367390009",
      "307632004",
      "288250001",
      "237282002",
      "237281009",
      "237279007",
      "206596003",
      "199008003",
      "199007008",
      "199005000",
      "199003007",
      "199002002",
      "199000005",
      "198999008",
      "198997005",
      "198986005",
      "198985009",
      "198984008",
      "198983002",
      "198968007",
      "198967002",
      "198966006",
      "198965005",
      "198953006",
      "198952001",
      "198951008",
      "198949009",
      "198947006",
      "198946002",
      "198945003",
      "198944004",
      "198942000",
      "198941007",
      "194791005",
      "194788005",
      "194785008",
      "194783001",
      "169465000",
      "123800009",
      "123799005",
      "111438007",
      "95605009",
      "89242004",
      "86041002",
      "84094009",
      "81626002",
      "78975002",
      "78808002",
      "74451002",
      "73410007",
      "72022006",
      "71874008",
      "70272006",
      "69909000",
      "67359005",
      "65518004",
      "65402008",
      "63287004",
      "59720008",
      "59621000",
      "57684003",
      "56218007",
      "52698002",
      "48552006",
      "48194001",
      "48146000",
      "46764007",
      "46481004",
      "41114007",
      "39727004",
      "39018007",
      "37618003",
      "35303009",
      "34694006",
      "31992008",
      "31407004",
      "29259002",
      "28119000",
      "26078007",
      "24042004",
      "23786008",
      "23717007",
      "23130000",
      "19769006",
      "18416000",
      "15394000",
      "14973001",
      "10725009",
      "10562009",
      "9901000",
      "8762007",
      "8218002",
      "1201005"
    ]
  },
  {
    "id": "hypertensive_kidney_disease",
    "snomed": [
      "104931000119100",
      "10757481000119107",
      "10757401000119104",
      "286371000119107",
      "285881000119109",
      "285871000119106",
      "285861000119100",
      "285851000119102",
      "285841000119104",
      "285831000119108",
      "285011000119108",
      "285001000119105",
      "284991000119104",
      "284981000119102",
      "284971000119100",
      "284961000119106",
      "153891000119101",
      "153851000119106",
      "129181000119109",
      "129171000119106",
      "129161000119100",
      "129151000119102",
      "128001000119105",
      "127991000119101",
      "118781000119108",
      "117681000119102",
      "111411000119103",
      "96751000119106",
      "96741000119109",
      "96731000119100",
      "96721000119103",
      "96711000119105",
      "96701000119107",
      "8501000119104",
      "712487000",
      "704667004"
    ]
  },
  {
    "id": "hypertrophic_cardiomyopathy",
    "snomed": [
      "233873004",
      "871649000",
      "871638006",
      "775908005",
      "771513008",
      "771509001",
      "771478008",
      "763280005",
      "725027004",
      "724173009",
      "720610008",
      "719272007",
      "718713000",
      "717812000",
      "704241002",
      "700065003",
      "472326004",
      "472325000",
      "472324001",
      "472318007",
      "472316006",
      "471885006",
      "445336009",
      "195020003",
      "93558006",
      "83978005",
      "63183009",
      "53322007",
      "45227007",
      "41964001"
    ]
  },
  {
    "id": "hysterectomy",
    "snomed": [
      "236886002",
      "860602007",
      "784191009",
      "767751001",
      "767612001",
      "767611008",
      "767610009",
      "762625001",
      "740515000",
      "740514001",
      "739674007",
      "739673001",
      "739672006",
      "739671004",
      "739670003",
      "739669004",
      "737099004",
      "722022003",
      "713157005",
      "708985003",
      "708878003",
      "708877008",
      "699789005",
      "608807008",
      "608806004",
      "608805000",
      "450692005",
      "450559006",
      "449727003",
      "448539002",
      "447844004",
      "447771005",
      "447237002",
      "446804002",
      "446679008",
      "446446002",
      "445766004",
      "441820006",
      "440383008",
      "440148001",
      "431316002",
      "428652006",
      "427107006",
      "414575003",
      "413145007",
      "413144006",
      "392018007",
      "390895000",
      "387646002",
      "387644004",
      "387643005",
      "387626007",
      "361223008",
      "361222003",
      "359983000",
      "359980002",
      "359977003",
      "359974005",
      "359971002",
      "310665001",
      "309880009",
      "309879006",
      "309876004",
      "307771009",
      "306966000",
      "305969000",
      "302191001",
      "302190000",
      "288043009",
      "288042004",
      "287931008",
      "287924009",
      "274974008",
      "265065000",
      "265059000",
      "265056007",
      "265054005",
      "236988000",
      "236987005",
      "236906003",
      "236902001",
      "236901008",
      "236897002",
      "236891001",
      "236889009",
      "236888001",
      "236887006",
      "236884004",
      "236876003",
      "236875004",
      "176895001",
      "176873000",
      "176795006",
      "176779006",
      "176776004",
      "176773007",
      "176761007",
      "176697007",
      "120038005",
      "116144002",
      "116143008",
      "116142003",
      "116141005",
      "116140006",
      "112918004",
      "88218008",
      "88144003",
      "86477000",
      "84064003",
      "82418001",
      "77902002",
      "75835007",
      "68143003",
      "63516002",
      "54535009",
      "54490004",
      "54261007",
      "54130005",
      "52712005",
      "49493009",
      "46226009",
      "43791001",
      "42010004",
      "41566006",
      "41059002",
      "36384005",
      "35955002",
      "31191000",
      "30160001",
      "29529008",
      "28301000",
      "27185000",
      "24293001",
      "24068006",
      "23140002",
      "17744000",
      "13254001",
      "12398007",
      "11050006",
      "9221009",
      "4176005",
      "647000",
      "161800001",
      "10738891000119107",
      "108941000119102",
      "860646004",
      "698448004",
      "473173007",
      "473171009",
      "429763009",
      "429290001",
      "428804004",
      "428078001",
      "275574006"
    ]
  },
  {
    "id": "iga_nephropathy",
    "snomed": [
      "68779003",
      "445404003"
    ]
  },
  {
    "id": "intellectual_disability",
    "snomed": [
      "228156007",
      "110359009",
      "838441009",
      "816067005",
      "788584007",
      "787175002",
      "787174003",
      "787171006",
      "785298001",
      "783703004",
      "783702009",
      "783619003",
      "783174004",
      "783061008",
      "783005002",
      "782945001",
      "782941005",
      "782886007",
      "782757004",
      "782755007",
      "782753000",
      "782723007",
      "778011005",
      "778009001",
      "777998000",
      "774203000",
      "774149004",
      "774070008",
      "773772001",
      "773769008",
      "773735007",
      "773692000",
      "773670004",
      "773621003",
      "773583007",
      "773578004",
      "773556006",
      "773554009",
      "773553003",
      "773552008",
      "773547003",
      "773494008",
      "773493002",
      "773419004",
      "773418007",
      "773416006",
      "773405004",
      "773404000",
      "773329005",
      "773307006",
      "773303005",
      "773274001",
      "772225005",
      "772224009",
      "772127009",
      "771477003",
      "771472009",
      "771470001",
      "771336003",
      "771262009",
      "771148008",
      "771077007",
      "771074000",
      "770941005",
      "770907002",
      "770901001",
      "770793002",
      "770751003",
      "770725000",
      "770721009",
      "770679002",
      "770604006",
      "770565003",
      "770564004",
      "770404004",
      "768843007",
      "768677000",
      "766871009",
      "766870005",
      "766753005",
      "765761009",
      "765758008",
      "765471005",
      "765434008",
      "764959000",
      "764950001",
      "764861005",
      "764455002",
      "763837007",
      "763797003",
      "763745005",
      "763744009",
      "763743003",
      "763742008",
      "763741001",
      "763722004",
      "763665007",
      "763626009",
      "763618001",
      "763404001",
      "763353000",
      "763344007",
      "763320005",
      "763278004",
      "763186006",
      "763136000",
      "734173003",
      "734017008",
      "733522005",
      "733419006",
      "733110004",
      "733097003",
      "733088002",
      "733072002",
      "733062000",
      "733050004",
      "733049004",
      "732961003",
      "732954002",
      "732251003",
      "732246009",
      "726732002",
      "726727003",
      "726672000",
      "726670008",
      "726669007",
      "726621009",
      "725912001",
      "725908007",
      "725906006",
      "725589005",
      "725289009",
      "724228005",
      "724178000",
      "724039002",
      "724001005",
      "723994004",
      "723621000",
      "723501008",
      "723454008",
      "723403008",
      "723365002",
      "723332005",
      "722478008",
      "722477003",
      "722459008",
      "722456001",
      "722455002",
      "722454003",
      "722380003",
      "722379001",
      "722378009",
      "722282008",
      "722281001",
      "722213009",
      "722209002",
      "722111004",
      "722107005",
      "722106001",
      "722105002",
      "722075004",
      "722065002",
      "722056009",
      "722055008",
      "722035007",
      "722031003",
      "722003007",
      "722002002",
      "721973006",
      "721883006",
      "721875000",
      "721843003",
      "721841001",
      "721224008",
      "721208007",
      "721146009",
      "721089006",
      "721087008",
      "721086004",
      "721017000",
      "721008000",
      "721007005",
      "720981000",
      "720979002",
      "720957007",
      "720955004",
      "720954000",
      "720825005",
      "720746006",
      "720635002",
      "720517001",
      "720502000",
      "720501007",
      "720401009",
      "719947004",
      "719909009",
      "719842006",
      "719826004",
      "719812008",
      "719811001",
      "719808002",
      "719800009",
      "719599008",
      "719450007",
      "719378009",
      "719212004",
      "719202006",
      "719162001",
      "719161008",
      "719157002",
      "719156006",
      "719155005",
      "719140001",
      "719139003",
      "719138006",
      "719136005",
      "719102004",
      "719101006",
      "719097002",
      "719046005",
      "719020006",
      "719018008",
      "719017003",
      "719016007",
      "719013004",
      "719012009",
      "719011002",
      "719010001",
      "719009006",
      "718914002",
      "718912003",
      "718911005",
      "718910006",
      "718909001",
      "718908009",
      "718905007",
      "718900002",
      "718897009",
      "718848000",
      "718845002",
      "718766002",
      "718681002",
      "718680001",
      "718573009",
      "718226002",
      "717945001",
      "717913006",
      "717887003",
      "717822006",
      "717763008",
      "717222003",
      "717157006",
      "716996008",
      "716709002",
      "716334004",
      "716191002",
      "716107009",
      "716096005",
      "716089008",
      "715989002",
      "715441004",
      "715428003",
      "715409005",
      "703526007",
      "703389002",
      "702816000",
      "702441001",
      "702416008",
      "702412005",
      "702356009",
      "699669001",
      "699297004",
      "432091002",
      "422437002",
      "412787009",
      "253176002",
      "234146006",
      "232059000",
      "86765009",
      "79385002",
      "77287004",
      "76880004",
      "68618008",
      "61152003",
      "59252009",
      "57917004",
      "40700009",
      "33982008",
      "31216003",
      "21634003",
      "17827007",
      "10007009",
      "5619004",
      "613003"
    ]
  },
  {
    "id": "irritable_bowel_syndrome",
    "snomed": [
      "10743008",
      "440630006",
      "440544005",
      "235840008",
      "235839006",
      "235838003",
      "197125005",
      "70871000119100"
    ]
  },
  {
    "id": "ischemic_stroke",
    "snomed": [
      "230690007",
      "16661971000119104",
      "16661931000119102",
      "16644681000119102",
      "16371781000119100",
      "16026991000119107",
      "16026951000119102",
      "16024271000119107",
      "16024151000119105",
      "16024111000119109",
      "16024031000119100",
      "16023991000119104",
      "16023911000119108",
      "16002111000119106",
      "16002031000119102",
      "16000511000119103",
      "16000431000119109",
      "16000391000119104",
      "16000351000119109",
      "330791000119108",
      "329671000119106",
      "329651000119102",
      "329641000119104",
      "329461000119102",
      "329451000119104",
      "329431000119105",
      "329421000119107",
      "329371000119101",
      "329361000119107",
      "292691000119103",
      "292681000119101",
      "292671000119104",
      "292661000119105",
      "140921000119102",
      "140911000119109",
      "106021000119105",
      "99451000119105",
      "9901000119100",
      "788884002",
      "788883008",
      "788882003",
      "788881005",
      "788880006",
      "725132001",
      "724994008",
      "724993002",
      "724429004",
      "724426006",
      "724425005",
      "724424009",
      "723082006",
      "722929005",
      "716051003",
      "444657001",
      "422504002",
      "413758000",
      "373606000",
      "371121002",
      "371041009",
      "371040005",
      "281240008",
      "276221006",
      "276220007",
      "275434003",
      "230715005",
      "230714009",
      "230713003",
      "230691006",
      "195217004",
      "195216008",
      "195213000",
      "195212005",
      "116288000",
      "111297002",
      "87555007",
      "78569004",
      "70936005",
      "57981008",
      "56267009",
      "39925003",
      "25772007",
      "25133001",
      "24654003",
      "14070001",
      "10349009"
    ]
  },
  {
    "id": "juvenile_polyposis",
    "snomed": [
      "9273005"
    ]
  },
  {
    "id": "kidney_cancer",
    "snomed": [
      "93849006",
      "15959101000119103",
      "15959061000119101",
      "1082331000119102",
      "1082321000119100",
      "1082301000119109",
      "1082281000119105",
      "354361000119107",
      "354351000119105",
      "782722002",
      "765095002",
      "764990003",
      "764961009",
      "764856008",
      "764694005",
      "734015000",
      "733608000",
      "733603009",
      "733471003",
      "733470002",
      "717736007",
      "717735006",
      "702391001",
      "408642003",
      "302849000",
      "254917006",
      "254916002",
      "254915003",
      "93985007",
      "128669006",
      "1443001",
      "254922006",
      "1081241000119107",
      "1081231000119103"
    ]
  },
  {
    "id": "kidney_dialysis",
    "snomed": [
      "265764009",
      "381000124105",
      "715743002",
      "714749008",
      "708934006",
      "708933000",
      "708932005",
      "708931003",
      "708930002",
      "698074000",
      "428648006",
      "427053002",
      "302497006",
      "288182009",
      "238323009",
      "238322004",
      "238321006",
      "238319001",
      "238318009",
      "233590002",
      "233589006",
      "233588003",
      "233587008",
      "233586004",
      "233585000",
      "233584001",
      "233583007",
      "233582002",
      "233581009",
      "233580005",
      "233579007",
      "233578004",
      "233577009",
      "233576000",
      "233575001",
      "225231007",
      "225230008",
      "73257006",
      "71192002",
      "68341005",
      "67970008",
      "57274006",
      "34897002",
      "14684005",
      "11932001",
      "3257008",
      "676002"
    ]
  },
  {
    "id": "kidney_transplant",
    "snomed": [
      "236436003"
    ]
  },
  {
    "id": "kidney_malformed",
    "snomed": [
      "92921005",
      "204984002",
      "723333000",
      "253869009",
      "41729002",
      "204941003",
      "32659003",
      "765155002",
      "765154003",
      "762914007",
      "762913001",
      "762910003",
      "762909008",
      "361147005",
      "361146001",
      "268232000",
      "253864004",
      "93290000",
      "18417009",
      "204942005",
      "783159001",
      "762912006",
      "762911004",
      "762908000",
      "762907005",
      "733116005",
      "41962002"
    ]
  },
  {
    "id": "proteinuria",
    "snomed": [
      "29738008",
      "368931000119104",
      "368921000119102",
      "368901000119106",
      "12511000132108",
      "12491000132101",
      "870311000",
      "870309009",
      "767577006",
      "370494002",
      "326915002",
      "325955007",
      "324984006",
      "324046003",
      "312975006",
      "274771005",
      "264867001",
      "263808002",
      "236720004",
      "197725000",
      "197724001",
      "197723007",
      "197722002",
      "197721009",
      "197720005",
      "197719004",
      "197717002",
      "197716006",
      "197708002",
      "197707007",
      "197688006",
      "89238002",
      "87865005",
      "77250007",
      "73883007",
      "73630001",
      "73014008",
      "61135002",
      "57009009",
      "55662002",
      "48160003",
      "43820004",
      "42827006",
      "40233000",
      "35727008",
      "34993002",
      "32482005",
      "23891001",
      "22794007",
      "17928001",
      "12178007",
      "8875000",
      "8022000",
      "7724006",
      "2740001",
      "2663001",
      "2657005"
    ]
  },
  {
    "id": "chronic_kidney_disease",
    "snomed": [
      "46177005",
      "707324008",
      "236435004",
      "236434000",
      "709044004",
      "691421000119108",
      "1801000119106",
      "776416004",
      "723190009",
      "722467000",
      "722150000",
      "722149000",
      "722098007",
      "714153000",
      "714152005",
      "713313000",
      "700379002",
      "700378005",
      "433146000",
      "433144002",
      "431857002",
      "431856006",
      "431855005",
      "425369003",
      "236433006",
      "90688005",
      "57557005",
      "49708008"
    ]
  },
  {
    "id": "focal_segmental_glomerulosclerosis",
    "snomed": [
      "236403004",
      "722147003",
      "722139003",
      "713887002",
      "445388002",
      "444977005",
      "236405006",
      "236404005"
    ]
  },
  {
    "id": "left_ventricular_non_compaction_syndrome",
    "snomed": [
      "447935001"
    ]
  },
  {
    "id": "leukemia",
    "snomed": [
      "93143009",
      "61301000119102",
      "61291000119103",
      "12311000132101",
      "12301000132103",
      "12291000132102",
      "12281000132104",
      "838355002",
      "838346000",
      "838344002",
      "838343008",
      "838342003",
      "838341005",
      "838340006",
      "788972003",
      "788874003",
      "780844005",
      "773537001",
      "770402000",
      "766048008",
      "766046007",
      "766045006",
      "764940002",
      "764855007",
      "763796007",
      "763309005",
      "762315004",
      "734522002",
      "733598001",
      "725437002",
      "725390002",
      "724644005",
      "723889003",
      "722795004",
      "721310007",
      "721308005",
      "721306009",
      "721305008",
      "714251006",
      "709471005",
      "703387000",
      "702446006",
      "699818003",
      "698646006",
      "449386007",
      "449108003",
      "445448008",
      "445227008",
      "444911000",
      "430338009",
      "427658007",
      "427642009",
      "427056005",
      "426642002",
      "426370008",
      "426248008",
      "426217000",
      "426124006",
      "425941003",
      "425869007",
      "425749006",
      "425688002",
      "415287001",
      "413847001",
      "413843002",
      "413842007",
      "413656006",
      "413442004",
      "413441006",
      "413389003",
      "404172001",
      "404154007",
      "404153001",
      "404152006",
      "404151004",
      "404139001",
      "371012000",
      "359648001",
      "359640008",
      "359631009",
      "307617006",
      "307592006",
      "307341004",
      "302856006",
      "302855005",
      "285839005",
      "285769009",
      "278453007",
      "278189009",
      "277619001",
      "277604002",
      "277602003",
      "277601005",
      "277589003",
      "277587001",
      "277575008",
      "277574007",
      "277573001",
      "277572006",
      "277571004",
      "277570003",
      "277569004",
      "277568007",
      "277567002",
      "277551008",
      "277550009",
      "277549009",
      "277545003",
      "277474005",
      "277473004",
      "190030009",
      "188770007",
      "188768003",
      "188754005",
      "188748009",
      "188746008",
      "188745007",
      "188744006",
      "188741003",
      "188737002",
      "188736006",
      "188734009",
      "188733003",
      "188732008",
      "188729005",
      "188728002",
      "188726003",
      "188725004",
      "188649008",
      "188648000",
      "188645002",
      "127225006",
      "118613001",
      "110007008",
      "110006004",
      "110005000",
      "110004001",
      "110002002",
      "109991003",
      "95210003",
      "95209008",
      "94716000",
      "94148006",
      "93451002",
      "93169003",
      "93152000",
      "93151007",
      "93150008",
      "93146001",
      "93145002",
      "93144003",
      "93142004",
      "92818009",
      "92817004",
      "92814006",
      "92813000",
      "92812005",
      "92811003",
      "91861009",
      "91860005",
      "91858008",
      "91857003",
      "91856007",
      "91855006",
      "91854005",
      "161436008",
      "152861000119104",
      "79081000119100",
      "63581000119104",
      "473058009",
      "427920005"
    ]
  },
  {
    "id": "li_fraumeni_syndrome",
    "snomed": [
      "428850001"
    ]
  },
  {
    "id": "lipoma",
    "snomed": [
      "93163002",
      "695751021000132104",
      "15974581000119103",
      "15974541000119108",
      "15863451000119107",
      "15638291000119105",
      "2460001000004103",
      "1080671000119108",
      "1080661000119102",
      "1080651000119104",
      "1080641000119101",
      "1079001000119106",
      "1078991000119106",
      "685821000119107",
      "685811000119100",
      "354431000119105",
      "104431000119107",
      "78501000119105",
      "33441000119100",
      "792799000",
      "774066000",
      "771076003",
      "762396004",
      "719049003",
      "716007007",
      "707368005",
      "699588004",
      "699538007",
      "473437005",
      "448446008",
      "448270009",
      "447952002",
      "426470009",
      "425719009",
      "423361002",
      "404066004",
      "404065000",
      "404064001",
      "404061009",
      "404060005",
      "404059000",
      "404058008",
      "404057003",
      "314336009",
      "308123002",
      "307754008",
      "301873005",
      "301872000",
      "301871007",
      "276891009",
      "255187008",
      "254921004",
      "253192007",
      "189017000",
      "189016009",
      "189015008",
      "189006001",
      "189004003",
      "189003009",
      "189002004",
      "189001006",
      "189000007",
      "188999005",
      "188998002",
      "188997007",
      "188996003",
      "188995004",
      "188994000",
      "188993006",
      "188992001",
      "188991008",
      "188990009",
      "188988008",
      "188987003",
      "188986007",
      "188985006",
      "188984005",
      "188983004",
      "188982009",
      "188981002",
      "109351006",
      "109350007",
      "93162007",
      "93161000",
      "93160004",
      "93159009",
      "93158001",
      "93157006",
      "93156002",
      "71404003",
      "16900351000119101"
    ]
  },
  {
    "id": "liver_cancer",
    "snomed": [
      "95214007",
      "860831008",
      "788982002",
      "770685009",
      "770684008",
      "721716004",
      "716648006",
      "447109003",
      "274902006",
      "253018005",
      "187769009",
      "109843000",
      "109842005",
      "109841003"
    ]
  },
  {
    "id": "long_qt",
    "snomed": [
      "9651007",
      "719907006",
      "715971003",
      "699256006",
      "442946007",
      "442917000",
      "422348008",
      "373905003",
      "20852007"
    ]
  },
  {
    "id": "lung_cancer",
    "snomed": [
      "93734005",
      "372120003",
      "313357008",
      "313356004",
      "313355000",
      "313354001",
      "313353007",
      "254622008",
      "109371002",
      "93882009",
      "93733004",
      "93732009",
      "93731002",
      "93730001",
      "93729006",
      "93880001",
      "12240991000119102",
      "12240951000119107",
      "1078961000119104",
      "1078931000119107",
      "1078901000119100",
      "1078881000119102",
      "683991000119103",
      "67841000119103",
      "67831000119107",
      "67821000119109",
      "67811000119102",
      "830151004",
      "830060005",
      "830055006",
      "822969007",
      "723301009",
      "722425009",
      "711414003",
      "707673006",
      "707672001",
      "707671008",
      "707670009",
      "707596000",
      "707595001",
      "707470000",
      "707469001",
      "707468009",
      "707467004",
      "707466008",
      "707465007",
      "707464006",
      "707460002",
      "707458004",
      "707457009",
      "707456000",
      "707455001",
      "707454002",
      "707453008",
      "707452003",
      "707451005",
      "707411007",
      "707410008",
      "707409003",
      "707408006",
      "707407001",
      "707405009",
      "707404008",
      "707403002",
      "703230006",
      "703228009",
      "440173001",
      "427038005",
      "426964009",
      "425376008",
      "425230006",
      "425048006",
      "424970000",
      "424938000",
      "424132000",
      "423600008",
      "423468007",
      "423295000",
      "423121009",
      "423050000",
      "422968005",
      "372136001",
      "372135002",
      "372113005",
      "372112000",
      "372111007",
      "372110008",
      "254638002",
      "254637007",
      "254635004",
      "254634000",
      "254633006",
      "254632001",
      "254631008",
      "254629004",
      "189815007",
      "93993007",
      "93992002",
      "93991009",
      "93865007",
      "93864006",
      "93827000",
      "429226001"
    ]
  },
  {
    "id": "lupus",
    "snomed": [
      "200936003",
      "295121000119101",
      "295111000119108",
      "295101000119105",
      "773333003",
      "724781003",
      "724767000",
      "713225000",
      "707301001",
      "698694005",
      "403488004",
      "403487009",
      "403486000",
      "309762007",
      "239890001",
      "239889005",
      "239888002",
      "239887007",
      "239886003",
      "201436003",
      "196138005",
      "95644001",
      "95609003",
      "80258006",
      "77753005",
      "76521009",
      "73286009",
      "68815009",
      "61458006",
      "55464009",
      "54912002",
      "54072008",
      "52042003",
      "36402006",
      "25380002",
      "22888007",
      "19682006",
      "11013005",
      "4676006"
    ]
  },
  {
    "id": "lupus_kidney_disease",
    "snomed": [
      "68815009",
      "76521009",
      "73286009",
      "52042003",
      "36402006",
      "11013005",
      "4676006"
    ]
  },
  {
    "id": "lymphoma",
    "snomed": [
      "118600007",
      "354851000119101",
      "352791000119108",
      "352411000119109",
      "352251000119109",
      "351211000119104",
      "350951000119101",
      "133751000119102",
      "117211000119105",
      "116871000119103",
      "116841000119105",
      "116821000119104",
      "116811000119106",
      "116691000119101",
      "866098005",
      "840424008",
      "840423002",
      "836486002",
      "830057003",
      "783541009",
      "773995001",
      "766935007",
      "765328000",
      "765136002",
      "763884007",
      "763666008",
      "763477007",
      "762690000",
      "736322001",
      "735332000",
      "734066005",
      "733627006",
      "726721002",
      "724648008",
      "724647003",
      "724645006",
      "722954005",
      "722953004",
      "721762007",
      "721555001",
      "718200007",
      "716788007",
      "715950008",
      "714463003",
      "713897006",
      "713718006",
      "713516007",
      "713483007",
      "713325002",
      "703626001",
      "702977001",
      "702786004",
      "702785000",
      "699657009",
      "449419008",
      "449418000",
      "449318001",
      "449307001",
      "449292003",
      "449222008",
      "449221001",
      "449220000",
      "449219006",
      "449218003",
      "449217008",
      "449216004",
      "449177007",
      "449176003",
      "449173006",
      "449075002",
      "449074003",
      "449072004",
      "449065000",
      "449063007",
      "449059000",
      "449058008",
      "449053004",
      "448995000",
      "448867004",
      "448865007",
      "448774004",
      "448738008",
      "448709005",
      "448672006",
      "448666006",
      "448663003",
      "448609001",
      "448607004",
      "448561007",
      "448560008",
      "448555009",
      "448553002",
      "448468003",
      "448465000",
      "448447004",
      "448387008",
      "448386004",
      "448384001",
      "448376000",
      "448372003",
      "448371005",
      "448354009",
      "448319002",
      "448317000",
      "448269008",
      "448254007",
      "448231003",
      "448220006",
      "448217003",
      "448213004",
      "448212009",
      "447989004",
      "447806008",
      "447805007",
      "447766003",
      "447658000",
      "447656001",
      "447100004",
      "445406001",
      "445269007",
      "444910004",
      "444597005",
      "443487006",
      "442537007",
      "441962003",
      "441559006",
      "427141003",
      "426885008",
      "426336007",
      "426071002",
      "422853008",
      "421283008",
      "421246008",
      "420788006",
      "420519005",
      "420302007",
      "415112005",
      "414785000",
      "414780005",
      "414166008",
      "413537009",
      "404157000",
      "404150003",
      "404149003",
      "404148006",
      "404147001",
      "404145009",
      "404144008",
      "404143002",
      "404142007",
      "404141000",
      "404140004",
      "404138009",
      "404137004",
      "404136008",
      "404135007",
      "404134006",
      "404133000",
      "404131003",
      "404130002",
      "404129007",
      "404128004",
      "404127009",
      "404126000",
      "404121005",
      "404120006",
      "404119000",
      "404118008",
      "404117003",
      "404116007",
      "404115006",
      "404114005",
      "404113004",
      "404112009",
      "404111002",
      "404110001",
      "404109006",
      "404108003",
      "404107008",
      "404106004",
      "402882001",
      "402881008",
      "402880009",
      "400122007",
      "400001003",
      "373168002",
      "371134001",
      "308121000",
      "307649006",
      "307647008",
      "307646004",
      "307637005",
      "307636001",
      "307635002",
      "307634003",
      "307633009",
      "307625008",
      "307624007",
      "307623001",
      "307622006",
      "303057009",
      "303056000",
      "303055001",
      "303017006",
      "302848008",
      "302845006",
      "302842009",
      "302841002",
      "285776004",
      "278052009",
      "278051002",
      "277664004",
      "277654008",
      "277653002",
      "277651000",
      "277643003",
      "277642008",
      "277637000",
      "277632006",
      "277629008",
      "277628000",
      "277627005",
      "277626001",
      "277625002",
      "277624003",
      "277623009",
      "277622004",
      "277618009",
      "277617004",
      "277616008",
      "277615007",
      "277614006",
      "277613000",
      "277612005",
      "277611003",
      "277610002",
      "277609007",
      "276836002",
      "276815004",
      "276811008",
      "274905008",
      "269476000",
      "255102004",
      "255101006",
      "254792006",
      "240531002",
      "236513009",
      "232075002",
      "190818004",
      "188718006",
      "188679001",
      "188676008",
      "188675007",
      "188674006",
      "188672005",
      "188637007",
      "188635004",
      "188634000",
      "188633006",
      "188632001",
      "188631008",
      "188627002",
      "188613007",
      "188612002",
      "188609000",
      "188593003",
      "188592008",
      "188591001",
      "188590000",
      "188589009",
      "188587006",
      "188586002",
      "188585003",
      "188582000",
      "188580008",
      "188579005",
      "188578002",
      "188577007",
      "188576003",
      "188575004",
      "188572001",
      "188570009",
      "188569008",
      "188568000",
      "188567005",
      "188566001",
      "188565002",
      "188562004",
      "188559002",
      "188558005",
      "188554007",
      "188551004",
      "188548006",
      "188547001",
      "188541000",
      "188538009",
      "188537004",
      "188536008",
      "188531003",
      "188529007",
      "188524002",
      "188517003",
      "188516007",
      "188515006",
      "188514005",
      "188513004",
      "188512009",
      "188511002",
      "188507008",
      "188506004",
      "188505000",
      "188504001",
      "188503007",
      "188502002",
      "188501009",
      "188500005",
      "188498009",
      "188493000",
      "188492005",
      "188489006",
      "188487008",
      "128875000",
      "127220001",
      "118618005",
      "118617000",
      "118611004",
      "118610003",
      "118609008",
      "118608000",
      "118607005",
      "118606001",
      "118605002",
      "118602004",
      "118601006",
      "118599009",
      "109979007",
      "109978004",
      "109977009",
      "109976000",
      "109975001",
      "109972003",
      "109971005",
      "109970006",
      "109969005",
      "109968002",
      "109967007",
      "109966003",
      "109965004",
      "109964000",
      "109962001",
      "95264000",
      "95263006",
      "95261008",
      "95260009",
      "95231000",
      "95230004",
      "95226002",
      "95225003",
      "95224004",
      "95194004",
      "95193005",
      "95192000",
      "95188007",
      "95187002",
      "95186006",
      "94715001",
      "94714002",
      "94712003",
      "94711005",
      "94710006",
      "94709001",
      "94708009",
      "94707004",
      "94690004",
      "94688000",
      "94687005",
      "94686001",
      "93555009",
      "93554008",
      "93552007",
      "93551000",
      "93550004",
      "93549004",
      "93548007",
      "93547002",
      "93546006",
      "93543003",
      "93542008",
      "93541001",
      "93537000",
      "93536009",
      "93534007",
      "93533001",
      "93532006",
      "93531004",
      "93530003",
      "93528000",
      "93527005",
      "93526001",
      "93525002",
      "93524003",
      "93523009",
      "93522004",
      "93521006",
      "93520007",
      "93519001",
      "93518009",
      "93516008",
      "93515007",
      "93514006",
      "93510002",
      "93509007",
      "93507009",
      "93506000",
      "93505001",
      "93501005",
      "93500006",
      "93498005",
      "93497000",
      "93496009",
      "93495008",
      "93494007",
      "93493001",
      "93492006",
      "93489007",
      "93488004",
      "93487009",
      "93199007",
      "93198004",
      "93197009",
      "93196000",
      "93195001",
      "93194002",
      "93193008",
      "93192003",
      "93191005",
      "92516002",
      "92513005",
      "92512000",
      "92511007",
      "58961005",
      "13048006",
      "429014004",
      "681291000119105",
      "152451000119104",
      "122571000119106",
      "108521000119103",
      "1881000119103",
      "789395008",
      "473068004",
      "428046009",
      "427846005"
    ]
  },
  {
    "id": "lynch_syndrome",
    "snomed": [
      "315058005",
      "716318002",
      "1099611000119109"
    ]
  },
  {
    "id": "macrocephaly",
    "snomed": [
      "19410003",
      "3961000119101",
      "763863002",
      "716189005",
      "716108004"
    ]
  },
  {
    "id": "macular_degeneration",
    "snomed": [
      "267718000",
      "792908009",
      "788967007",
      "788913001",
      "414875008",
      "414173003",
      "314427006",
      "312899005",
      "264633009",
      "16535401000119102"
    ]
  },
  {
    "id": "malignant_hyperthermia_susceptibility",
    "snomed": [
      "405501007",
      "719398004",
      "213026003"
    ]
  },
  {
    "id": "marfan_syndrome",
    "snomed": [
      "19346006",
      "16055631000119106",
      "15993551000119100",
      "15960021000119107",
      "773644000",
      "763839005",
      "234035006",
      "57201002"
    ]
  },
  {
    "id": "membranous_nephropathy",
    "snomed": [
      "77182004",
      "28191000119109",
      "722168002",
      "722120008",
      "722119002",
      "722086002",
      "236417008",
      "236416004",
      "236415000",
      "236414001",
      "236413007"
    ]
  },
  {
    "id": "multiple_endocrine_neoplasia_type_1",
    "snomed": [
      "30664006"
    ]
  },
  {
    "id": "multiple_endocrine_neoplasia_type_2",
    "snomed": [
      "61808009",
      "94281000119101",
      "18831000119107",
      "721188000"
    ]
  },
  {
    "id": "multiple_sclerosis",
    "snomed": [
      "24700007",
      "816984002",
      "766246000",
      "733028000",
      "724778008",
      "439567002",
      "438511000",
      "428700003",
      "426373005",
      "425500002",
      "230372003",
      "192929006",
      "192927008",
      "192926004"
    ]
  },
  {
    "id": "muscle_cancer",
    "snomed": [
      "363495004",
      "278024000",
      "188269007",
      "93913006",
      "93912001",
      "93911008",
      "93910009",
      "93909004",
      "93908007",
      "93907002",
      "93906006",
      "93905005",
      "93904009",
      "93903003",
      "93902008",
      "93901001",
      "93900000",
      "93899005",
      "93772000"
    ]
  },
  {
    "id": "mutyh_associated_polyposis",
    "snomed": [
      "423471004"
    ]
  },
  {
    "id": "neuroendocrine_tumor",
    "snomed": [
      "255046005",
      "682821000119102",
      "352071000119105",
      "351461000119100",
      "133881000119100",
      "133871000119103",
      "133841000119105",
      "133531000119104",
      "130571000119107",
      "130521000119106",
      "130461000119102",
      "130451000119104",
      "123631000119103",
      "870355006",
      "818967003",
      "789721005",
      "789004008",
      "785824001",
      "783006001",
      "773775004",
      "773774000",
      "762665002",
      "737313007",
      "737312002",
      "735735001",
      "735718000",
      "734099007",
      "733353006",
      "733351008",
      "733350009",
      "733348001",
      "733163007",
      "733162002",
      "725167001",
      "722683006",
      "722528008",
      "722527003",
      "721701002",
      "721700001",
      "721698005",
      "721697000",
      "721673009",
      "721671006",
      "721670007",
      "721645007",
      "721644006",
      "721642005",
      "721641003",
      "721640002",
      "721639004",
      "721638007",
      "721637002",
      "721636006",
      "721635005",
      "721634009",
      "721626003",
      "721625004",
      "721194008",
      "721193002",
      "718604008",
      "717922007",
      "717921000",
      "717919005",
      "717918002",
      "717917007",
      "717916003",
      "716653001",
      "716652006",
      "715903004",
      "709284003",
      "707625001",
      "707594002",
      "703321001",
      "699704002",
      "699028006",
      "443333004",
      "432328008",
      "431902003",
      "422886007",
      "404810003",
      "403973004",
      "403972009",
      "403971002",
      "403970001",
      "403969002",
      "302834008",
      "302824004",
      "302823005",
      "281566005",
      "281565009",
      "281564008",
      "281563002",
      "281562007",
      "255047001",
      "255042007",
      "255040004",
      "255039001",
      "255038009",
      "254795008",
      "254729005",
      "253031000",
      "253030004",
      "253008000",
      "253006001",
      "253005002",
      "253001006",
      "253000007",
      "237832001",
      "55937004",
      "277993001",
      "128658008",
      "127574006",
      "127573000",
      "127572005",
      "81622000"
    ]
  },
  {
    "id": "nevoid_basal_cell_carcinoma_syndrome",
    "snomed": [
      "69408002"
    ]
  },
  {
    "id": "obesity",
    "snomed": [
      "414916001",
      "15750121000119108",
      "10750551000119100",
      "1076711000119101",
      "1076701000119104",
      "83911000119104",
      "171000119107",
      "788996008",
      "785722006",
      "783719006",
      "783549006",
      "773663004",
      "770680004",
      "722596001",
      "722053001",
      "721231007",
      "717761005",
      "717269008",
      "702949005",
      "444862003",
      "415530009",
      "414920002",
      "414919008",
      "414918000",
      "414917005",
      "414438005",
      "360566006",
      "298464002",
      "297500005",
      "296526005",
      "295509007",
      "294493008",
      "293481008",
      "292464007",
      "290439001",
      "270486005",
      "248312008",
      "248311001",
      "238136002",
      "238135003",
      "238134004",
      "238133005",
      "238132000",
      "190966007",
      "190965006",
      "111036000",
      "82793005",
      "80660001",
      "72894001",
      "63702009",
      "62999006",
      "57337005",
      "53146006",
      "44772007",
      "5036006",
      "161453001",
      "16093531000119101",
      "43991000119102"
    ]
  },
  {
    "id": "obsessive_compulsive_disorder",
    "snomed": [
      "191736004",
      "762516004",
      "762332000",
      "724730008",
      "724693000",
      "723913009",
      "192014006",
      "191738003",
      "191737008"
    ]
  },
  {
    "id": "osteoporosis",
    "snomed": [
      "64859006",
      "11314841000119105",
      "11311841000119107",
      "11311801000119105",
      "11311641000119106",
      "11311601000119109",
      "11311521000119104",
      "11311441000119109",
      "11311361000119103",
      "739301006",
      "735618008",
      "735617003",
      "707419009",
      "704336007",
      "704333004",
      "704330001",
      "704293000",
      "704292005",
      "704291003",
      "704290002",
      "704289006",
      "704288003",
      "704287008",
      "704286004",
      "704285000",
      "704284001",
      "704283007",
      "704282002",
      "703264005",
      "699528002",
      "431031001",
      "390833005",
      "311806008",
      "281387004",
      "276661002",
      "268028001",
      "240198002",
      "240164006",
      "240162005",
      "240161003",
      "240160002",
      "240159007",
      "240158004",
      "240157009",
      "240156000",
      "240155001",
      "240154002",
      "203657009",
      "203453001",
      "203452006",
      "203451004",
      "203450003",
      "203448006",
      "203447001",
      "203446005",
      "203445009",
      "203444008",
      "203438009",
      "203437004",
      "203435007",
      "203434006",
      "203433000",
      "203429007",
      "109346000",
      "102447009",
      "53174001",
      "32369003",
      "18040001",
      "15743005",
      "14651005",
      "3345002",
      "473120007",
      "473342003"
    ]
  },
  {
    "id": "other_hereditary_disease",
    "snomed": [
      "363005004",
      "785808002",
      "783787000",
      "783096008",
      "782823001",
      "782773005",
      "782724001",
      "778061001",
      "778060000",
      "776416004",
      "774148007",
      "773749003",
      "773587008",
      "773426004",
      "771515001",
      "771179007",
      "770784003",
      "770687001",
      "770435005",
      "766937004",
      "764965000",
      "764732004",
      "764457005",
      "764452004",
      "764100007",
      "763867001",
      "763778003",
      "763619009",
      "763615003",
      "763279007",
      "763066009",
      "733491005",
      "733454004",
      "726704006",
      "725145002",
      "724361001",
      "724357007",
      "724208006",
      "724066002",
      "724002003",
      "723448007",
      "723363009",
      "723336008",
      "723333000",
      "722211006",
      "722206009",
      "722027009",
      "722006004",
      "721979005",
      "721978002",
      "721976003",
      "721073008",
      "721009008",
      "720748007",
      "720639008",
      "720612000",
      "720606005",
      "720605009",
      "720575002",
      "720567008",
      "720512007",
      "720507006",
      "719456001",
      "719400000",
      "719379001",
      "718602007",
      "718551002",
      "718212006",
      "718135001",
      "717859007",
      "717824007",
      "716740009",
      "716193004",
      "715987000",
      "715655000",
      "715644000",
      "711164003",
      "703533007",
      "703369003",
      "703219008",
      "702428000",
      "700063005",
      "697899000",
      "472320005",
      "461415008",
      "459062008",
      "458432002",
      "419097006",
      "403776002",
      "403775003",
      "403765001",
      "373420004",
      "297253000",
      "238764001",
      "237867001",
      "236529001",
      "191329002",
      "95656000",
      "68926002",
      "68504005",
      "56453003",
      "45639009",
      "42402006",
      "21877004",
      "19092004",
      "16652001",
      "723304001",
      "11164009",
      "860814001",
      "860813007",
      "860812002",
      "860811009",
      "838307002",
      "827115000",
      "818959006",
      "818952002",
      "789159005",
      "789157007",
      "789063000",
      "787410005",
      "787407003",
      "787093004",
      "786037006",
      "785727000",
      "785725008",
      "785305006",
      "784391002",
      "784380009",
      "784377008",
      "784371009",
      "784351000",
      "784344009",
      "784341001",
      "783774006",
      "783770002",
      "783698005",
      "783622001",
      "783620009",
      "783618006",
      "783616005",
      "783556000",
      "783555001",
      "783550006",
      "783258000",
      "783250007",
      "783242003",
      "783202008",
      "783194008",
      "783182004",
      "783179009",
      "783160006",
      "783146009",
      "783143001",
      "783142006",
      "783136007",
      "783097004",
      "783089006",
      "783057002",
      "783055005",
      "782946000",
      "782937006",
      "782935003",
      "782911008",
      "782910009",
      "782881002",
      "782880001",
      "782829002",
      "782824007",
      "782781006",
      "782756008",
      "782752005",
      "782743001",
      "782742006",
      "782736007",
      "782718007",
      "782670003",
      "781386002",
      "778070003",
      "778069004",
      "778062008",
      "778051008",
      "778042000",
      "778008009",
      "778006008",
      "778004006",
      "778003000",
      "778001003",
      "776417008",
      "776204008",
      "774152007",
      "774069007",
      "774068004",
      "773985008",
      "773984007",
      "773768000",
      "773750003",
      "773727009",
      "773700005",
      "773667003",
      "773666007",
      "773664005",
      "773646003",
      "773584001",
      "773581009",
      "773577009",
      "773551001",
      "773549000",
      "773495009",
      "773489008",
      "773422002",
      "773406003",
      "773400009",
      "773397000",
      "773393001",
      "773345007",
      "773300008",
      "772130002",
      "772129007",
      "771512003",
      "771511005",
      "771474005",
      "771471002",
      "771342004",
      "771339005",
      "771338002",
      "771334000",
      "771306007",
      "771300001",
      "771272007",
      "771269000",
      "771263004",
      "771239007",
      "771186004",
      "771182002",
      "771181009",
      "771178004",
      "771143004",
      "771081007",
      "771080008",
      "770947009",
      "770946000",
      "770945001",
      "770943008",
      "770900000",
      "770794008",
      "770792007",
      "770791000",
      "770788000",
      "770786001",
      "770759001",
      "770756008",
      "770723007",
      "770719004",
      "770667002",
      "770630005",
      "770627003",
      "770626007",
      "770594005",
      "770558006",
      "770431001",
      "770406002",
      "770405003",
      "768556005",
      "766992008",
      "766987006",
      "766928004",
      "766820007",
      "766812005",
      "766765009",
      "766717008",
      "766715000",
      "765747004",
      "765746008",
      "765745007",
      "765744006",
      "765330003",
      "765325002",
      "765202001",
      "765195000",
      "765096001",
      "765092004",
      "765057007",
      "765046002",
      "764992006",
      "764963007",
      "764958008",
      "764957003",
      "764945007",
      "764859001",
      "764854006",
      "764850002",
      "764810000",
      "764730007",
      "764522009",
      "764437006",
      "763886009",
      "763885008",
      "763866005",
      "763776004",
      "763770005",
      "763767006",
      "763755009",
      "763748007",
      "763718009",
      "763716008",
      "763715007",
      "763686007",
      "763669001",
      "763616002",
      "763534009",
      "763402002",
      "763387005",
      "763375003",
      "763374004",
      "763369007",
      "763368004",
      "763352005",
      "763130006",
      "763070001",
      "763069002",
      "763068005",
      "763067000",
      "737227004",
      "734022008",
      "734021001",
      "734020000",
      "734018003",
      "733604003",
      "733489002",
      "733466005",
      "733456002",
      "733422008",
      "733117001",
      "733093004",
      "733089005",
      "733087007",
      "733065003",
      "733064004",
      "733046006",
      "733045005",
      "733029008",
      "732958004",
      "732957009",
      "732956000",
      "732955001",
      "732949006",
      "732948003",
      "732927000",
      "732261005",
      "726734001",
      "726724005",
      "726709001",
      "726708009",
      "726629006",
      "726622002",
      "726619004",
      "726107008",
      "726079008",
      "726018006",
      "726017001",
      "725905005",
      "725904009",
      "725903003",
      "725590001",
      "725411000",
      "725409009",
      "725393000",
      "725392005",
      "725295005",
      "725165009",
      "725151007",
      "725150008",
      "725140007",
      "725101002",
      "725096002",
      "725057008",
      "725050005",
      "725035001",
      "725034002",
      "725030006",
      "724999003",
      "724765008",
      "724384008",
      "724349009",
      "724284005",
      "724283004",
      "724282009",
      "724224007",
      "724207001",
      "724147004",
      "724145007",
      "724140002",
      "724139004",
      "724069009",
      "724064004",
      "724015007",
      "723827003",
      "723820001",
      "723819007",
      "723721007",
      "723623002",
      "723584003",
      "723582004",
      "723556008",
      "723552005",
      "723512008",
      "723504000",
      "723502001",
      "723499000",
      "723497003",
      "723446006",
      "723444009",
      "723441001",
      "723411003",
      "723409007",
      "723408004",
      "723407009",
      "723386002",
      "723385003",
      "723373006",
      "723366001",
      "723359002",
      "722859001",
      "722759007",
      "722493007",
      "722476007",
      "722457005",
      "722452004",
      "722450007",
      "722449007",
      "722439009",
      "722433005",
      "722377004",
      "722298001",
      "722296002",
      "722294004",
      "722293005",
      "722292000",
      "722288007",
      "722210007",
      "722203001",
      "722125003",
      "722122000",
      "722117000",
      "722109008",
      "722108000",
      "722063009",
      "722034006",
      "722032005",
      "722019000",
      "722008003",
      "721974000",
      "721972001",
      "721904001",
      "721902002",
      "721888002",
      "721882001",
      "721878003",
      "721842008",
      "721840000",
      "721834007",
      "721584005",
      "721235003",
      "721234004",
      "721228006",
      "721227001",
      "721220004",
      "721219005",
      "721148005",
      "721096008",
      "721092005",
      "721091003",
      "721082002",
      "721075001",
      "721072003",
      "721069005",
      "720987001",
      "720984008",
      "720980004",
      "720952001",
      "720855003",
      "720826006",
      "720818003",
      "720817008",
      "720813007",
      "720754008",
      "720752007",
      "720747002",
      "720634003",
      "720598005",
      "720574003",
      "720573009",
      "720572004",
      "720570007",
      "720569006",
      "720521008",
      "720498007",
      "720492008",
      "720468000",
      "720467005",
      "720464003",
      "720457000",
      "720456009",
      "720415006",
      "720412009",
      "719990003",
      "719989007",
      "719988004",
      "719987009",
      "719986000",
      "719985001",
      "719980006",
      "719979008",
      "719951002",
      "719950001",
      "719949001",
      "719910004",
      "719843001",
      "719689005",
      "719595002",
      "719583002",
      "719520001",
      "719518004",
      "719517009",
      "719516000",
      "719515001",
      "719514002",
      "719513008",
      "719512003",
      "719511005",
      "719510006",
      "719471002",
      "719466009",
      "719453009",
      "719431007",
      "719397009",
      "719306007",
      "719305006",
      "719304005",
      "719302009",
      "719301002",
      "719300001",
      "719298001",
      "719296002",
      "719278006",
      "719276005",
      "719274008",
      "719271000",
      "719266007",
      "719255000",
      "719254001",
      "719253007",
      "719252002",
      "719251009",
      "719250005",
      "719249005",
      "719210007",
      "719209002",
      "719208005",
      "719207000",
      "719204007",
      "719203001",
      "719171005",
      "719159004",
      "719158007",
      "719098007",
      "719069008",
      "719042007",
      "719041000",
      "719019000",
      "718851007",
      "718774001",
      "718772002",
      "718771009",
      "718770005",
      "718769009",
      "718765003",
      "718764004",
      "718763005",
      "718761007",
      "718759003",
      "718756005",
      "718755009",
      "718754008",
      "718753002",
      "718752007",
      "718691008",
      "718679004",
      "718631006",
      "718603002",
      "718576001",
      "718575002",
      "718572004",
      "718553004",
      "718222000",
      "718193005",
      "718178006",
      "718122005",
      "718106009",
      "718103001",
      "718099006",
      "717975006",
      "717968005",
      "717944002",
      "717920004",
      "717915004",
      "717911008",
      "717825008",
      "717792007",
      "717785002",
      "717766000",
      "717338006",
      "717337001",
      "717336005",
      "717286002",
      "717264003",
      "717256009",
      "717254007",
      "717225001",
      "717221005",
      "717192003",
      "717184007",
      "717183001",
      "717182006",
      "717048002",
      "717046003",
      "717045004",
      "717016001",
      "717014003",
      "717013009",
      "717012004",
      "717011006",
      "717010007",
      "717008005",
      "716994006",
      "716862002",
      "716774008",
      "716772007",
      "716743006",
      "716742001",
      "716741008",
      "716724006",
      "716700003",
      "716697002",
      "716696006",
      "716664003",
      "716662004",
      "716456000",
      "716245003",
      "716243005",
      "716233007",
      "716232002",
      "716230005",
      "716197003",
      "716166002",
      "716112005",
      "716111003",
      "716105001",
      "716092007",
      "716090004",
      "716024001",
      "716008002",
      "715988005",
      "715908008",
      "715899006",
      "715830008",
      "715829003",
      "715826005",
      "715825009",
      "715824008",
      "715807002",
      "715793003",
      "715789009",
      "715788001",
      "715768000",
      "715755008",
      "715754007",
      "715753001",
      "715752006",
      "715751004",
      "715748006",
      "715726000",
      "715725001",
      "715724002",
      "715723008",
      "715722003",
      "715721005",
      "715720006",
      "715710001",
      "715674008",
      "715673002",
      "715670004",
      "715666007",
      "715665006",
      "715657008",
      "715656004",
      "715645004",
      "715630006",
      "715629001",
      "715576000",
      "715568002",
      "715564000",
      "715531000",
      "715528001",
      "715526002",
      "715472000",
      "715440003",
      "715439000",
      "715404000",
      "715374003",
      "715344006",
      "715339004",
      "715317001",
      "715314008",
      "715242008",
      "715219001",
      "712922002",
      "711265009",
      "711162004",
      "711158005",
      "711156009",
      "711154007",
      "711152006",
      "709105005",
      "707747007",
      "707273001",
      "703544004",
      "703541007",
      "703535000",
      "703534001",
      "703532002",
      "703531009",
      "703528008",
      "703504006",
      "703310005",
      "702450004",
      "702448007",
      "702443003",
      "702431004",
      "702429008",
      "702427005",
      "702426001",
      "702425002",
      "702421006",
      "702411003",
      "702410002",
      "702398007",
      "702393003",
      "702384004",
      "702383005",
      "702378002",
      "702375004",
      "702373006",
      "702367005",
      "702362004",
      "702361006",
      "702359002",
      "702355008",
      "702350003",
      "702349003",
      "702344008",
      "702339001",
      "702323008",
      "702312009",
      "700211007",
      "700057001",
      "699870002",
      "699861000",
      "699754008",
      "699447001",
      "699316006",
      "699298009",
      "699275001",
      "699190008",
      "698851003",
      "449821007",
      "448054001",
      "446449009",
      "446263001",
      "445252005",
      "427167008",
      "421455009",
      "421182009",
      "420932006",
      "419395007",
      "419197009",
      "419039007",
      "417065002",
      "416633008",
      "414380008",
      "413936007",
      "403819009",
      "403817006",
      "403816002",
      "403815003",
      "403811007",
      "403805009",
      "403794008",
      "403787005",
      "403786001",
      "403785002",
      "403784003",
      "403783009",
      "402770002",
      "402460000",
      "401046009",
      "400211001",
      "400085009",
      "400040008",
      "400018004",
      "400014002",
      "399889006",
      "399091004",
      "398229007",
      "398170002",
      "398071000",
      "398040009",
      "397568004",
      "389274008",
      "389272007",
      "389203001",
      "361199007",
      "359725000",
      "359717002",
      "359700009",
      "312921000",
      "312214005",
      "294705005",
      "254234005",
      "254219004",
      "254174005",
      "254173004",
      "254172009",
      "254171002",
      "254170001",
      "254169002",
      "254168005",
      "254167000",
      "254164007",
      "254158000",
      "254125009",
      "254090007",
      "254044004",
      "253880009",
      "253879006",
      "253878003",
      "253828000",
      "240078009",
      "240076008",
      "240075007",
      "240069003",
      "240068006",
      "240067001",
      "239133004",
      "239068002",
      "238855000",
      "238687000",
      "238054000",
      "237890006",
      "237889002",
      "237612000",
      "236535001",
      "236418003",
      "236385009",
      "234969005",
      "234963006",
      "234962001",
      "234961008",
      "234620006",
      "234619000",
      "234484005",
      "234422006",
      "234138005",
      "232373003",
      "232052009",
      "231930000",
      "230553002",
      "230410004",
      "230319002",
      "230313001",
      "230301006",
      "230300007",
      "230299004",
      "230263009",
      "205824006",
      "205821003",
      "205583005",
      "205481009",
      "205480005",
      "205258009",
      "204152008",
      "204138006",
      "193411004",
      "128115005",
      "128114009",
      "128113003",
      "128106003",
      "111388003",
      "111306001",
      "111304003",
      "109494000",
      "109447008",
      "109433009",
      "95842004",
      "92824003",
      "92503002",
      "87006007",
      "86426007",
      "85880000",
      "82725007",
      "81854007",
      "79468000",
      "77097004",
      "75654009",
      "75443009",
      "73284007",
      "73073009",
      "70410008",
      "68116008",
      "67510007",
      "66758006",
      "66185005",
      "65976001",
      "63684002",
      "63387002",
      "61530001",
      "61165007",
      "59229005",
      "58833000",
      "58756001",
      "57938005",
      "54209007",
      "51409009",
      "48611009",
      "46808003",
      "45853006",
      "45283008",
      "44641000",
      "43532007",
      "42986003",
      "42295001",
      "41590007",
      "40632002",
      "36524008",
      "31742004",
      "26409005",
      "22199006",
      "21367009",
      "18822004",
      "15123008",
      "14087004",
      "9133005",
      "8857001",
      "8634009",
      "7731005",
      "7037003",
      "5994005",
      "5134006",
      "4463009",
      "4183003",
      "2625009",
      "2065009",
      "1674008",
      "290006",
      "109478007",
      "733031004",
      "733032006",
      "719810000",
      "721207002",
      "722110003",
      "723676007",
      "725163002",
      "763861000",
      "765089003",
      "765170001",
      "768473009",
      "773230003",
      "773498006",
      "773548008",
      "770755007",
      "770898002",
      "773699009",
      "782772000",
      "780827006",
      "785726009",
      "788417006",
      "716706009",
      "718577005",
      "719825000",
      "722033000",
      "733417008",
      "763795006",
      "763773007",
      "718896000",
      "719160009",
      "722037004",
      "763350002",
      "774102003",
      "719834005",
      "719600006",
      "770790004",
      "766824003",
      "720982007",
      "733472005",
      "733116005",
      "733455003",
      "733086003",
      "724137002",
      "770750002",
      "771149000",
      "771448004",
      "778025006",
      "733623005",
      "733650000",
      "770414008",
      "726106004",
      "717768004",
      "717767009",
      "773647007",
      "715628009",
      "399340005",
      "368941000119108",
      "367561000119103",
      "367551000119100",
      "367541000119102",
      "367511000119101",
      "236422008",
      "236419006",
      "234485006",
      "83923004"
    ]
  },
  {
    "id": "other",
    "snomed": [
      "722201004"
    ]
  },
  {
    "id": "ovarian_cancer",
    "snomed": [
      "363443007",
      "15635721000119108",
      "827162007",
      "770601003",
      "763131005",
      "733361001",
      "722685004",
      "722684000",
      "716855006",
      "716588005",
      "702368000",
      "423627007",
      "422782004",
      "369590004",
      "369567009",
      "369566000",
      "369559001",
      "369555007",
      "369540003",
      "369538008",
      "369534005",
      "369533004",
      "369532009",
      "369531002",
      "369529006",
      "369528003",
      "369527008",
      "369526004",
      "369525000",
      "369524001",
      "369522002",
      "314191009",
      "254876005",
      "254874008",
      "254872007",
      "254870004",
      "254869000",
      "254863004",
      "254860001",
      "254856004",
      "254852002",
      "254851009",
      "254850005",
      "254849005",
      "93934004",
      "429090009",
      "672291000119107",
      "141951000119104"
    ]
  },
  {
    "id": "pancreatic_cancer",
    "snomed": [
      "363418001",
      "681721000119103",
      "681621000119105",
      "1651000119109",
      "866052009",
      "866048009",
      "792907004",
      "785879009",
      "783771003",
      "783706007",
      "782697005",
      "780821007",
      "770602005",
      "721718003",
      "715414009",
      "713189001",
      "700423003",
      "372142002",
      "372119009",
      "372003004",
      "371967001",
      "363419009",
      "363417006",
      "363369002",
      "363368005",
      "326072005",
      "314964006",
      "255088001",
      "254612002",
      "254611009",
      "254609000",
      "235966007",
      "187798008",
      "187794005",
      "187793004",
      "187792009",
      "187791002",
      "187786003",
      "109848009",
      "94082003",
      "93939009",
      "93843007",
      "93715005"
    ]
  },
  {
    "id": "panic_disorder",
    "snomed": [
      "371631005",
      "192037000",
      "111491004",
      "111490003",
      "89948007",
      "87798009",
      "83631006",
      "82738004",
      "82494000",
      "76868007",
      "76812003",
      "74010007",
      "72861004",
      "65064003",
      "64060000",
      "63909006",
      "63701002",
      "61212007",
      "59923000",
      "56576003",
      "53956006",
      "50983008",
      "49564006",
      "43150009",
      "38328002",
      "35607004",
      "34116005",
      "32388005",
      "31781004",
      "30059008",
      "24781009",
      "22230001",
      "19766004",
      "11941006",
      "8185002",
      "5509004",
      "4932002",
      "3158007",
      "1816003"
    ]
  },
  {
    "id": "paraganglioma",
    "snomed": [
      "302833002",
      "765143008",
      "764999002",
      "253029009"
    ]
  },
  {
    "id": "parathyroid_adenoma",
    "snomed": [
      "92272009",
      "128475008",
      "128474007"
    ]
  },
  {
    "id": "peripheral_arterial_disease",
    "snomed": [
      "399957001",
      "18000001000004101",
      "16014871000119101",
      "16014791000119105",
      "16014711000119101",
      "16014671000119100",
      "16013151000119102",
      "16013111000119103",
      "16013031000119107",
      "16012991000119101",
      "16012951000119106",
      "16012911000119105",
      "16012831000119109",
      "16012791000119103",
      "16012711000119107",
      "16012671000119108",
      "16012591000119105",
      "16012551000119100",
      "16012511000119101",
      "16011431000119103",
      "16011391000119108",
      "16009431000119100",
      "16009271000119101",
      "16007871000119108",
      "16007751000119109",
      "16005471000119100",
      "16005391000119108",
      "15972541000119104",
      "15972501000119101",
      "15972461000119101",
      "15967341000119100",
      "15967301000119102",
      "10749961000119104",
      "116291000119103",
      "735574006",
      "735572005",
      "734299002",
      "734298005",
      "733127007",
      "733126003",
      "724441006",
      "723872006",
      "723870003",
      "713825007",
      "698816006",
      "431706008",
      "427592000",
      "427567003",
      "427184002",
      "425527003",
      "425414000",
      "405588004",
      "405557003",
      "405556007",
      "405545007",
      "314189001",
      "314188009",
      "314187004",
      "312829002",
      "312828005",
      "312827000",
      "312826009",
      "312825008",
      "312496009",
      "312380005",
      "312378004",
      "312377009",
      "312375001",
      "306852006",
      "306849003",
      "306848006",
      "302910002",
      "302300004",
      "302233006",
      "300921000",
      "300919005",
      "297162006",
      "297155002",
      "297154003",
      "297153009",
      "297152004",
      "297151006",
      "297150007",
      "297149007",
      "297146000",
      "297141005",
      "297140006",
      "297136002",
      "297135003",
      "289923007",
      "286959000",
      "282664001",
      "276500007",
      "271432005",
      "243410003",
      "243286001",
      "236488005",
      "235842000",
      "233974006",
      "233973000",
      "233969003",
      "233968006",
      "233967001",
      "197000003",
      "196999001",
      "195341003",
      "195339004",
      "195335005",
      "195327007",
      "195326003",
      "195325004",
      "195324000",
      "195323006",
      "195321008",
      "195320009",
      "195319003",
      "195318006",
      "95580006",
      "95579008",
      "93388003",
      "90958004",
      "87239004",
      "82453008",
      "80383008",
      "60989005",
      "52156004",
      "51677000",
      "33591000",
      "31529002",
      "840580004",
      "16899261000119106",
      "16019511000119108",
      "16019471000119104",
      "16018911000119107",
      "16018831000119106",
      "15649941000119102",
      "15648821000119105",
      "15648771000119103",
      "15648731000119101",
      "15648691000119108",
      "15648601000119102",
      "12237231000119107",
      "12237111000119107",
      "12236991000119103",
      "153911000119104",
      "8001000119106",
      "792844003",
      "724440007",
      "709587006",
      "709585003",
      "709584004",
      "442735001",
      "442701004",
      "442693003",
      "442439008",
      "51274000",
      "45281005",
      "431545001",
      "430305000",
      "430301009",
      "789544005"
    ]
  },
  {
    "id": "personality_disorder",
    "snomed": [
      "33449004",
      "698700004",
      "698699000",
      "698690001",
      "473457009",
      "473456000",
      "442245003",
      "274952002",
      "268634009",
      "268633003",
      "231530005",
      "231528008",
      "231527003",
      "231526007",
      "231525006",
      "231456001",
      "231455002",
      "231454003",
      "231453009",
      "231452004",
      "192527004",
      "192097003",
      "192073007",
      "191774007",
      "191773001",
      "191772006",
      "191766006",
      "191765005",
      "191753006",
      "191565008",
      "191564007",
      "191562006",
      "191561004",
      "191559008",
      "191496002",
      "129606007",
      "88845000",
      "84466009",
      "83890006",
      "80711002",
      "55341008",
      "52954000",
      "46721000",
      "44966003",
      "40987004",
      "37746008",
      "36217008",
      "31611000",
      "31027006",
      "26665006",
      "20010003",
      "16805009",
      "13601005",
      "4306003",
      "1376001"
    ]
  },
  {
    "id": "peutz_jeghers_syndrome",
    "snomed": [
      "54411001",
      "53633000"
    ]
  },
  {
    "id": "pheochromoctyoma",
    "snomed": [
      "302835009",
      "1079421000119109",
      "1079411000119102",
      "21851000119103",
      "765143008",
      "732968009",
      "253032007",
      "76564002",
      "85583005",
      "817952000",
      "307575002",
      "29370006",
      "49221000119108"
    ]
  },
  {
    "id": "pituitary_adenoma",
    "snomed": [
      "254956000",
      "788756004",
      "778046002",
      "446945009",
      "264906008",
      "254966008",
      "254965007",
      "254964006",
      "254963000",
      "254962005",
      "254961003",
      "254960002",
      "254959007",
      "254958004",
      "254957009",
      "253011004",
      "253010003",
      "237719001",
      "134209002",
      "691191000119103",
      "49241000119102"
    ]
  },
  {
    "id": "primary_biliary_cirrhosis",
    "snomed": [
      "31712002",
      "715401008"
    ]
  },
  {
    "id": "prostate_cancer",
    "snomed": [
      "399068003",
      "1098981000119101",
      "822970008",
      "722103009",
      "712849003",
      "427492003",
      "399490008",
      "314969001",
      "254900004",
      "93974005"
    ]
  },
  {
    "id": "ptsd",
    "snomed": [
      "47505003",
      "699241002",
      "446180007",
      "446175003",
      "443919007",
      "318784009",
      "317816007",
      "313182004",
      "192042008"
    ]
  },
  {
    "id": "rectal_cancer",
    "snomed": [
      "363351006",
      "184881000119106",
      "96981000119102",
      "766979005",
      "448994001",
      "425178004",
      "369454008",
      "369453002",
      "369452007",
      "369451000",
      "369450004",
      "369449004",
      "369448007",
      "363414004",
      "314966008",
      "269544008",
      "254586002",
      "254582000",
      "187760008",
      "109839004",
      "93984006",
      "93980002",
      "429084005",
      "10987871000119109"
    ]
  },
  {
    "id": "retinal_detachment",
    "snomed": [
      "42059000",
      "15997671000119100",
      "15997631000119103",
      "15997591000119103",
      "15733161000119107",
      "15733121000119102",
      "15733081000119104",
      "15733041000119109",
      "15733001000119107",
      "677431000119105",
      "677421000119107",
      "345811000119101",
      "343721000119103",
      "343481000119106",
      "343471000119108",
      "343221000119108",
      "340501000119106",
      "338131000119105",
      "337951000119101",
      "337711000119100",
      "337701000119103",
      "337451000119106",
      "337431000119100",
      "334891000119105",
      "332521000119102",
      "332341000119100",
      "332101000119100",
      "332091000119105",
      "331831000119109",
      "331811000119104",
      "331111000119108",
      "82571000119107",
      "82541000119100",
      "31191000119102",
      "31181000119100",
      "778021002",
      "723023003",
      "713345005",
      "712508005",
      "712507000",
      "712506009",
      "425356002",
      "425062004",
      "420030000",
      "415501002",
      "361130009",
      "314494006",
      "314026007",
      "314009001",
      "314008009",
      "314007004",
      "314006008",
      "312957005",
      "312956001",
      "312947009",
      "312924008",
      "312923002",
      "247181004",
      "247165009",
      "247159009",
      "247158001",
      "232069006",
      "232068003",
      "232067008",
      "232034009",
      "232023006",
      "232015009",
      "232014008",
      "232013002",
      "232012007",
      "232011000",
      "232010004",
      "232009009",
      "232008001",
      "232004004",
      "193497004",
      "193329008",
      "193327005",
      "193326001",
      "193325002",
      "193324003",
      "193323009",
      "193322004",
      "193320007",
      "193319001",
      "95493003",
      "86923008",
      "83405000",
      "65545003",
      "56202001",
      "52002008",
      "51987004",
      "50165004",
      "44268007",
      "38599001",
      "38579007",
      "34711008",
      "19620000",
      "11391004",
      "7219007",
      "4178006",
      "3598000",
      "161487006",
      "10691841000119107"
    ]
  },
  {
    "id": "retinoblastoma",
    "snomed": [
      "371986009",
      "234941000119100",
      "735920008",
      "735919002",
      "735918005",
      "735916009",
      "721547008",
      "721546004",
      "370967009",
      "312949007",
      "109948008",
      "94128004",
      "93998003",
      "93987004",
      "93766000",
      "93764002",
      "93756008",
      "93755007",
      "19906005"
    ]
  },
  {
    "id": "rheumatoid_arthritis",
    "snomed": [
      "69896004",
      "15687321000119109",
      "15687201000119107",
      "15686281000119101",
      "11055151000119108",
      "1073831000119107",
      "1073821000119109",
      "1073811000119102",
      "1073801000119100",
      "1073791000119101",
      "1073781000119104",
      "1073751000119106",
      "1073741000119109",
      "1073731000119100",
      "1073721000119103",
      "1073711000119105",
      "1073701000119107",
      "319841000119107",
      "319081000119109",
      "143441000119108",
      "781206002",
      "735600005",
      "735599007",
      "429192004",
      "427770001",
      "399923009",
      "398640008",
      "308143008",
      "287008006",
      "287007001",
      "287006005",
      "239795001",
      "239792003",
      "239791005",
      "201791009",
      "201785007",
      "201784006",
      "201783000",
      "201781003",
      "201780002",
      "201779000",
      "201778008",
      "201777003",
      "201776007",
      "201775006",
      "201774005",
      "201773004",
      "201772009",
      "201771002",
      "201770001",
      "201769002",
      "201768005",
      "201767000",
      "201766009",
      "201764007",
      "86219005",
      "57160007",
      "52661003",
      "28880005",
      "7607008",
      "161567008"
    ]
  },
  {
    "id": "schizophrenia",
    "snomed": [
      "58214004",
      "441833000",
      "416340002",
      "268617001",
      "247804008",
      "191577003",
      "191563001",
      "191555002",
      "191554003",
      "191548004",
      "191547009",
      "191542003",
      "191531007",
      "191527001",
      "191526005",
      "111484002",
      "111483008",
      "111482003",
      "85861002",
      "83746006",
      "79866005",
      "79204003",
      "76566000",
      "71103003",
      "70814008",
      "68995007",
      "64905009",
      "63181006",
      "51133006",
      "42868002",
      "39610001",
      "38295006",
      "35252006",
      "35218008",
      "31658008",
      "31373002",
      "30336007",
      "29599000",
      "27387000",
      "26472000",
      "26025008",
      "16990005",
      "14291003",
      "12939007",
      "7025000",
      "4926007",
      "161468000"
    ]
  },
  {
    "id": "sclerosing_cholangitis",
    "snomed": [
      "197441003",
      "735733008"
    ]
  },
  {
    "id": "seizure",
    "snomed": [
      "91175000",
      "322112361000132104",
      "10750951000119106",
      "434551000124106",
      "434541000124109",
      "291311000119108",
      "290881000119103",
      "290871000119101",
      "290761000119103",
      "290741000119102",
      "290721000119108",
      "290711000119101",
      "290691000119104",
      "290681000119102",
      "290671000119100",
      "187931000119106",
      "137991000119103",
      "119001000119108",
      "117891000119100",
      "116401000119105",
      "100941000119100",
      "84231000119103",
      "84221000119101",
      "84211000119108",
      "84201000119105",
      "84191000119107",
      "84181000119109",
      "84171000119106",
      "84161000119100",
      "82381000119103",
      "68091000119102",
      "53521000119106",
      "47391000119107",
      "21391000119102",
      "20121000119105",
      "14401000119109",
      "10701000119109",
      "8291000119107",
      "3371000119106",
      "981000119108",
      "860815000",
      "860806007",
      "860804005",
      "838351006",
      "785303004",
      "784372002",
      "784345005",
      "784342008",
      "783739005",
      "783139000",
      "783064000",
      "783062001",
      "782825008",
      "782737003",
      "778063003",
      "778047006",
      "773643006",
      "773497001",
      "773421009",
      "771469002",
      "771303004",
      "771223000",
      "771142009",
      "771141002",
      "770758009",
      "770678005",
      "770643005",
      "770624005",
      "770623004",
      "770622009",
      "770438007",
      "768666006",
      "768555009",
      "767254005",
      "766932005",
      "766815007",
      "766044005",
      "765756007",
      "765216006",
      "765093009",
      "764453009",
      "763827002",
      "763802009",
      "763632004",
      "763622006",
      "763349002",
      "735235000",
      "734434007",
      "733195008",
      "733082001",
      "726702005",
      "725413002",
      "724992007",
      "724991000",
      "724990004",
      "724989008",
      "724988000",
      "724789001",
      "724788009",
      "724787004",
      "724786008",
      "724785007",
      "724728006",
      "724727001",
      "724672003",
      "724671005",
      "724670006",
      "724669005",
      "724668002",
      "724576005",
      "724549005",
      "724274009",
      "723926008",
      "723127000",
      "723126009",
      "723125008",
      "722762005",
      "722386009",
      "721088003",
      "717276003",
      "717223008",
      "716278005",
      "715534008",
      "715425000",
      "710046001",
      "703524005",
      "703150000",
      "702753003",
      "702326000",
      "699688008",
      "698767004",
      "698764006",
      "698763000",
      "698762005",
      "698761003",
      "698760002",
      "698021005",
      "449203004",
      "445355009",
      "445095002",
      "444229001",
      "443410001",
      "442512002",
      "442511009",
      "442481002",
      "441678004",
      "440443001",
      "438156004",
      "438113009",
      "437871001",
      "433083002",
      "432354000",
      "426634003",
      "425349008",
      "425237009",
      "425054007",
      "423086004",
      "422873003",
      "422724001",
      "422527005",
      "422513000",
      "413101007",
      "407675009",
      "372441001",
      "371115001",
      "371114002",
      "371107004",
      "371022006",
      "361268000",
      "361267005",
      "361123003",
      "352818000",
      "313307000",
      "308742005",
      "308680003",
      "307357004",
      "307356008",
      "307200007",
      "303063000",
      "279953009",
      "278510009",
      "277130004",
      "276597004",
      "268956009",
      "267592003",
      "267581004",
      "246552000",
      "246551007",
      "246550008",
      "246549008",
      "246548000",
      "246546001",
      "246545002",
      "246544003",
      "246542004",
      "246541006",
      "246540007",
      "246539005",
      "246538002",
      "246537007",
      "246536003",
      "246535004",
      "246533006",
      "246532001",
      "246531008",
      "246530009",
      "246529004",
      "246528007",
      "237283007",
      "230460005",
      "230459000",
      "230458008",
      "230457003",
      "230456007",
      "230454005",
      "230453004",
      "230452009",
      "230450001",
      "230448009",
      "230447004",
      "230445007",
      "230444006",
      "230443000",
      "230441003",
      "230440002",
      "230439004",
      "230438007",
      "230437002",
      "230435005",
      "230433003",
      "230432008",
      "230431001",
      "230430000",
      "230429005",
      "230428002",
      "230427007",
      "230426003",
      "230425004",
      "230423006",
      "230422001",
      "230421008",
      "230420009",
      "230419003",
      "230418006",
      "230417001",
      "230416005",
      "230415009",
      "230414008",
      "230413002",
      "230412007",
      "230411000",
      "230408001",
      "230407006",
      "230406002",
      "230405003",
      "230404004",
      "230403005",
      "230401007",
      "230400008",
      "230399001",
      "230398009",
      "230397004",
      "230396008",
      "230395007",
      "230394006",
      "230393000",
      "230392005",
      "230391003",
      "230390002",
      "230389006",
      "230388003",
      "230387008",
      "230386004",
      "230384001",
      "230383007",
      "230382002",
      "230381009",
      "230191005",
      "198993009",
      "198992004",
      "198991006",
      "198990007",
      "193022009",
      "193021002",
      "193010006",
      "193009001",
      "193008009",
      "193004006",
      "193003000",
      "193002005",
      "193000002",
      "192999003",
      "192993002",
      "192991000",
      "192990004",
      "192982004",
      "192981006",
      "192979009",
      "192845009",
      "163595003",
      "163594004",
      "163593005",
      "163591007",
      "163590008",
      "163588007",
      "128613002",
      "128612007",
      "111498005",
      "95208000",
      "89525009",
      "87476004",
      "87185006",
      "87095001",
      "84757009",
      "83752007",
      "82401000",
      "79745005",
      "79631006",
      "79348005",
      "75023009",
      "74737003",
      "73840001",
      "73706008",
      "72103000",
      "71831005",
      "71587006",
      "68761002",
      "66264000",
      "65120008",
      "61927004",
      "61484000",
      "60338000",
      "60317006",
      "59754009",
      "59457001",
      "58895005",
      "54200006",
      "53482009",
      "52125007",
      "51887003",
      "51075009",
      "50866000",
      "49776008",
      "49644006",
      "49255002",
      "44145005",
      "42440009",
      "42365007",
      "41510006",
      "41497008",
      "41119002",
      "40816002",
      "39745004",
      "39194005",
      "38281008",
      "37356005",
      "36803009",
      "34601006",
      "33941008",
      "29963001",
      "29753000",
      "28055006",
      "23374007",
      "20544001",
      "19598007",
      "19593003",
      "19334007",
      "18191000",
      "16873003",
      "15938005",
      "15523002",
      "14521008",
      "13973009",
      "13847000",
      "9112001",
      "7689009",
      "7454002",
      "7033004",
      "6208003",
      "6204001",
      "4619009",
      "4103001",
      "2665008",
      "2198002",
      "241006",
      "161480008"
    ]
  },
  {
    "id": "sickle_cell_disease",
    "snomed": [
      "417357006",
      "10759351000119103",
      "783254003",
      "444108000",
      "440206000",
      "417748003",
      "417683006",
      "417517009",
      "417425009",
      "417279003",
      "417048006",
      "416826005",
      "416638004",
      "416484003",
      "416290001",
      "416214006",
      "416180004",
      "234391009",
      "127048005",
      "127047000",
      "127045008",
      "127044007",
      "127043001",
      "127042006",
      "127041004",
      "127040003",
      "47024008",
      "36472007",
      "35434009",
      "25472008",
      "23269001"
    ]
  },
  {
    "id": "sickle_cell_trait",
    "snomed": [
      "16402000",
      "10759401000119105",
      "72321000119107",
      "127046009"
    ]
  },
  {
    "id": "thalassemia",
    "snomed": [
      "40108008",
      "10806241000119108",
      "72331000119105",
      "788944005",
      "734349003",
      "718196002",
      "716682000",
      "715342005",
      "447117006",
      "417048006",
      "416826005",
      "307343001",
      "269175006",
      "234390005",
      "234389001",
      "234388009",
      "234387004",
      "234386008",
      "234385007",
      "234383000",
      "191189009",
      "191187006",
      "127046009",
      "127045008",
      "127044007",
      "127043001",
      "127042006",
      "127041004",
      "111572002",
      "89810003",
      "89459006",
      "86715000",
      "86242003",
      "85557000",
      "85422000",
      "84188003",
      "79592006",
      "76336008",
      "75451007",
      "73190000",
      "69216008",
      "68913001",
      "66055002",
      "65959000",
      "62074008",
      "61777009",
      "61395005",
      "56205004",
      "54006005",
      "48553001",
      "47084006",
      "47047009",
      "39586009",
      "36472007",
      "36467003",
      "32855007",
      "27080008",
      "26682008",
      "23371004",
      "19442009",
      "16964007",
      "16427007",
      "16360009",
      "15326009",
      "5967006",
      "5300004",
      "934007"
    ]
  },
  {
    "id": "skin_cancer",
    "snomed": [
      "372130007",
      "1082221000119106",
      "1082101000119106",
      "1081021000119109",
      "1079191000119104",
      "1079171000119100",
      "1079161000119106",
      "1079151000119109",
      "1079131000119103",
      "1079111000119108",
      "1079101000119105",
      "1079091000119100",
      "352201000119105",
      "352001000119100",
      "351961000119109",
      "236811000119101",
      "79281000119106",
      "61471000119100",
      "827186009",
      "726019003",
      "724866001",
      "722712000",
      "721541009",
      "721540005",
      "717731002",
      "716274007",
      "449637003",
      "449636007",
      "449634005",
      "449631002",
      "449630001",
      "449416001",
      "449309003",
      "448300007",
      "448298007",
      "448296006",
      "448273006",
      "447738006",
      "447712006",
      "447710003",
      "443136000",
      "429114002",
      "425148008",
      "424666005",
      "424487008",
      "424302003",
      "424260006",
      "423896007",
      "423829008",
      "423700001",
      "423535002",
      "423506005",
      "423494003",
      "423463003",
      "423447006",
      "423425006",
      "423349005",
      "423325007",
      "423284006",
      "423280002",
      "422676009",
      "422599000",
      "422572002",
      "422378004",
      "404156009",
      "404155008",
      "404124002",
      "404123008",
      "404122003",
      "404093001",
      "404092006",
      "404091004",
      "404087009",
      "404047004",
      "404046008",
      "404045007",
      "404017001",
      "404016005",
      "404015009",
      "404014008",
      "404010004",
      "404009009",
      "404008001",
      "403996004",
      "403955002",
      "403954003",
      "403951006",
      "403950007",
      "403949007",
      "403947009",
      "403946000",
      "403944002",
      "403943008",
      "403942003",
      "403941005",
      "403940006",
      "403939009",
      "403929003",
      "403919005",
      "403918002",
      "403917007",
      "403916003",
      "403915004",
      "403914000",
      "403913006",
      "403912001",
      "403911008",
      "403910009",
      "403909004",
      "403899005",
      "403898002",
      "403897007",
      "403896003",
      "403895004",
      "403894000",
      "403893006",
      "403892001",
      "403891008",
      "403742006",
      "403729006",
      "403714009",
      "403713003",
      "403712008",
      "403711001",
      "403468003",
      "403274000",
      "402912009",
      "402911002",
      "402879006",
      "402873007",
      "402820007",
      "402819001",
      "402818009",
      "402652009",
      "402547008",
      "402546004",
      "402545000",
      "402544001",
      "402543007",
      "402542002",
      "402541009",
      "402540005",
      "402539008",
      "402538000",
      "402537005",
      "402536001",
      "402534003",
      "402533009",
      "402532004",
      "402531006",
      "402530007",
      "402529002",
      "402528005",
      "402527000",
      "402526009",
      "402525008",
      "402524007",
      "402523001",
      "402522006",
      "402521004",
      "402520003",
      "402519009",
      "402518001",
      "402517006",
      "402516002",
      "402515003",
      "402514004",
      "402513005",
      "402512000",
      "402511007",
      "402510008",
      "402509003",
      "402508006",
      "402507001",
      "402506005",
      "402505009",
      "402504008",
      "402503002",
      "402502007",
      "402501000",
      "402500004",
      "402499008",
      "402498000",
      "402497005",
      "402496001",
      "402495002",
      "402494003",
      "400175006",
      "400174005",
      "400173004",
      "400057007",
      "399954008",
      "399934007",
      "399897004",
      "398903003",
      "398670003",
      "372129002",
      "372128005",
      "372127000",
      "372126009",
      "372125008",
      "372124007",
      "372123001",
      "372122006",
      "314976006",
      "314975005",
      "311779007",
      "307610008",
      "307603002",
      "307599002",
      "302837001",
      "286891000",
      "285631006",
      "285309005",
      "285308002",
      "285307007",
      "276860003",
      "276799004",
      "276751004",
      "276750003",
      "276738009",
      "275265005",
      "271467005",
      "255096006",
      "255068000",
      "254908006",
      "254898001",
      "254820002",
      "254800003",
      "254797000",
      "254794007",
      "254771006",
      "254764001",
      "254748009",
      "254734009",
      "254733003",
      "254732008",
      "254731001",
      "254730000",
      "254727007",
      "254726003",
      "254714008",
      "254713002",
      "254712007",
      "254711000",
      "254710004",
      "254709009",
      "254708001",
      "254707006",
      "254703005",
      "254702000",
      "254701007",
      "254655003",
      "254654004",
      "254653005",
      "254652000",
      "254651007",
      "254650008",
      "253042009",
      "231833004",
      "231832009",
      "231831002",
      "188458007",
      "188454009",
      "188138001",
      "188135003",
      "188133005",
      "188132000",
      "188125009",
      "188122007",
      "188121000",
      "188110009",
      "188107002",
      "188103003",
      "188102008",
      "188100000",
      "188099008",
      "188095002",
      "188091006",
      "188090007",
      "188089003",
      "188042000",
      "188033007",
      "109386008",
      "109267002",
      "109264009",
      "94617002",
      "94579000",
      "94578008",
      "94577003",
      "94576007",
      "94575006",
      "94574005",
      "94573004",
      "94572009",
      "94571002",
      "94570001",
      "94569002",
      "94568005",
      "94567000",
      "94566009",
      "94565008",
      "94564007",
      "94562006",
      "94561004",
      "94560003",
      "94559008",
      "94558000",
      "94557005",
      "94556001",
      "94555002",
      "94554003",
      "94553009",
      "94552004",
      "94551006",
      "94550007",
      "94549007",
      "94548004",
      "94547009",
      "94546000",
      "94545001",
      "94544002",
      "94543008",
      "94542003",
      "94540006",
      "94539009",
      "94532000",
      "94483003",
      "94159002",
      "94081005",
      "94047004",
      "94046008",
      "94045007",
      "94044006",
      "94043000",
      "94042005",
      "94041003",
      "94040002",
      "94039004",
      "94038007",
      "94037002",
      "94036006",
      "94035005",
      "94034009",
      "94033003",
      "94032008",
      "94030000",
      "94029005",
      "94028002",
      "94027007",
      "94026003",
      "94025004",
      "94024000",
      "94023006",
      "94022001",
      "94021008",
      "94020009",
      "94019003",
      "94018006",
      "94017001",
      "94016005",
      "94015009",
      "94014008",
      "94013002",
      "94012007",
      "94011000",
      "94010004",
      "94008001",
      "94007006",
      "94000008",
      "93956001",
      "93655004",
      "93654000",
      "93653006",
      "93652001",
      "93651008",
      "93650009",
      "93649009",
      "93648001",
      "93647006",
      "93646002",
      "93645003",
      "93644004",
      "93643005",
      "93642000",
      "93641007",
      "93640008",
      "93638003",
      "93637008",
      "93636004",
      "93230002",
      "93229007",
      "93228004",
      "93227009",
      "93226000",
      "93225001",
      "93224002",
      "93223008",
      "93222003",
      "93221005",
      "93220006",
      "93219000",
      "93218008",
      "93217003",
      "93216007",
      "93215006",
      "93214005",
      "93213004",
      "93211002",
      "93210001",
      "93209006",
      "62497000",
      "372244006",
      "1080981000119104",
      "1080941000119109",
      "866082003",
      "866079008",
      "866073009",
      "866072004",
      "830150003",
      "816204007",
      "783736003",
      "723281005",
      "722665003",
      "722543005",
      "721627007",
      "721563000",
      "720587009",
      "707362006",
      "707361004",
      "707350007",
      "698288007",
      "698287002",
      "698286006",
      "698285005",
      "698045009",
      "698044008",
      "698043002",
      "698042007",
      "698041000",
      "698040004",
      "443493003",
      "424190005",
      "423673009",
      "403927001",
      "403926005",
      "403925009",
      "403924008",
      "403923002",
      "403922007",
      "403921000",
      "403920004",
      "402564006",
      "402563000",
      "402562005",
      "402561003",
      "402560002",
      "402559007",
      "402558004",
      "399660006",
      "313248004",
      "310498001",
      "277530005",
      "276822007",
      "276821000",
      "274087000",
      "269581007",
      "269580008",
      "269579005",
      "269578002",
      "255021005",
      "255015006",
      "255012009",
      "255004001",
      "254896002",
      "231834005",
      "188077000",
      "188076009",
      "188075008",
      "188074007",
      "188073001",
      "188072006",
      "188071004",
      "188070003",
      "188069004",
      "188068007",
      "188065005",
      "188064009",
      "188063003",
      "188062008",
      "188061001",
      "188060000",
      "188055004",
      "188054000",
      "188052001",
      "188051008",
      "188050009",
      "188049009",
      "188046002",
      "188045003",
      "188044004",
      "188040008",
      "188038003",
      "188034001",
      "188032002",
      "188030005",
      "161432005",
      "115561000119102",
      "321000119108",
      "2092003",
      "399634005",
      "399521000",
      "399475009",
      "428053000",
      "69411000119107",
      "429024007",
      "254767008",
      "404044006",
      "404043000",
      "254769006",
      "254768003"
    ]
  },
  {
    "id": "small_bowel_cancer",
    "snomed": [
      "363509000",
      "184891000119109",
      "838349007",
      "764735002",
      "724537009",
      "724536000",
      "721669006",
      "716651004",
      "448664009",
      "424440001",
      "408644002",
      "363405009",
      "363404008",
      "363403002",
      "304545002",
      "255078002",
      "254570009",
      "187752007",
      "109837002",
      "94048009",
      "93890009",
      "93846004",
      "93832004",
      "93775003",
      "428833003",
      "139611000119108",
      "73771000119104"
    ]
  },
  {
    "id": "social_phobia",
    "snomed": [
      "25501002",
      "191726007",
      "191725006",
      "191724005",
      "62351001"
    ]
  },
  {
    "id": "stomach_cancer",
    "snomed": [
      "363349007",
      "681631000119108",
      "766980008",
      "766757006",
      "721643000",
      "721633003",
      "721632008",
      "721630000",
      "721629005",
      "716586009",
      "447785000",
      "447707005",
      "408647009",
      "372143007",
      "372014001",
      "314961003",
      "276810009",
      "276809004",
      "269460009",
      "269459004",
      "255078002",
      "254567005",
      "254563009",
      "254561006",
      "254559002",
      "254557000",
      "254555008",
      "254553001",
      "187742008",
      "187741001",
      "187740000",
      "187738005",
      "187736009",
      "187733001",
      "187732006",
      "109836006",
      "93977003",
      "93976007",
      "93867004",
      "93818001",
      "93809003",
      "93738008",
      "93717002",
      "126824007",
      "860830009",
      "448373008",
      "445736006",
      "415655008",
      "399967006",
      "276812001",
      "255168002",
      "254564003",
      "235686008",
      "126831006",
      "126830007",
      "126829002",
      "126828005",
      "126827000",
      "126826009",
      "126825008",
      "95017007",
      "95016003",
      "94907009",
      "94858007",
      "94849000",
      "94777000",
      "94758002",
      "92756002",
      "92693001",
      "92692006",
      "92604004",
      "92598002",
      "92560002",
      "92549006",
      "92411005",
      "92311006",
      "92310007",
      "92183009",
      "92125000",
      "92116006",
      "92042009",
      "92020008",
      "36162004",
      "473061005"
    ]
  },
  {
    "id": "hyperthyroidism",
    "snomed": [
      "34486009",
      "10754921000119106",
      "137421000119106",
      "67761000119108",
      "722942005",
      "722941003",
      "703309000",
      "427970008",
      "360361000",
      "360358001",
      "360353005",
      "353295004",
      "286909009",
      "267374005",
      "237824009",
      "237549001",
      "237547004",
      "237512007",
      "237510004",
      "237509009",
      "237508001",
      "237507006",
      "237506002",
      "237505003",
      "237504004",
      "237503005",
      "237501007",
      "237500008",
      "237499004",
      "237498007",
      "190256007",
      "190255006",
      "190247004",
      "190244006",
      "190242005",
      "190241003",
      "90739004",
      "89719007",
      "88740003",
      "87232008",
      "73869005",
      "69329005",
      "67723002",
      "66628005",
      "62278002",
      "62052002",
      "60268006",
      "60216004",
      "59957008",
      "57777000",
      "55807009",
      "44744000",
      "40607004",
      "30985009",
      "29028009",
      "27538003",
      "26389007",
      "26151008",
      "25008002",
      "22350004",
      "15470004",
      "13795004",
      "5834008",
      "5604000",
      "4997005",
      "479003",
      "161442007",
      "137871000119109"
    ]
  },
  {
    "id": "hypothyroidism",
    "snomed": [
      "40930008",
      "10809101000119109",
      "369091000119106",
      "367631000119105",
      "102871000119101",
      "783177006",
      "773987000",
      "771510006",
      "770631009",
      "763890006",
      "725462002",
      "722940002",
      "722939004",
      "722938007",
      "722375007",
      "718690009",
      "718194004",
      "718183003",
      "717334008",
      "717333002",
      "716338001",
      "698577000",
      "440092001",
      "428165003",
      "405630007",
      "405629002",
      "360348000",
      "278503003",
      "276630006",
      "276566003",
      "237695004",
      "237567008",
      "237566004",
      "237565000",
      "237560005",
      "237559000",
      "237556007",
      "237555006",
      "237528002",
      "237527007",
      "237526003",
      "237521008",
      "237520009",
      "237519003",
      "237518006",
      "237515009",
      "216693007",
      "190284002",
      "190283008",
      "190282003",
      "190279008",
      "190277005",
      "190268003",
      "111567006",
      "111566002",
      "93359004",
      "89261000",
      "88273006",
      "84781002",
      "83986005",
      "83664006",
      "82598004",
      "78574007",
      "75065003",
      "70225006",
      "64491003",
      "63127008",
      "63115005",
      "60733007",
      "57185003",
      "56041007",
      "55838005",
      "54823002",
      "52724003",
      "50375007",
      "49830003",
      "43507005",
      "43153006",
      "42785009",
      "42277004",
      "40539002",
      "39444001",
      "37429009",
      "30229009",
      "27059002",
      "26692000",
      "23536000",
      "22558005",
      "18621008",
      "17885001",
      "14779006",
      "10718002",
      "4641009",
      "2917005",
      "161443002"
    ]
  },
  {
    "id": "thyroid_cancer",
    "snomed": [
      "255032005",
      "429016002",
      "255028004",
      "255029007",
      "717734005",
      "708971008",
      "429255009",
      "428064002",
      "94098005",
      "13351431000119102",
      "786038001",
      "772992009",
      "724553007",
      "724552002",
      "716657000",
      "424887002",
      "423158009",
      "255031003",
      "255030002",
      "429254008"
    ]
  },
  {
    "id": "thyroid_nodule",
    "snomed": [
      "237495005",
      "237496006",
      "419153005",
      "237571006",
      "237570007",
      "237569006",
      "190247004",
      "190244006",
      "190237002",
      "73869005",
      "69329005",
      "62278002",
      "57777000",
      "36241006",
      "30985009",
      "26389007"
    ]
  },
  {
    "id": "tuberous_sclerosis_complex",
    "snomed": [
      "7199000",
      "765331004",
      "254243001",
      "233718008",
      "36025004"
    ]
  },
  {
    "id": "ulcerative_colitis",
    "snomed": [
      "64766004",
      "1092841000119100",
      "1085231000119100",
      "1085131000119105",
      "697969008",
      "445243001",
      "444548001",
      "444546002",
      "442159003",
      "441971007",
      "414156000",
      "410484008",
      "295046003",
      "235714007",
      "196987008",
      "128600008",
      "78712000",
      "78324009",
      "52506002",
      "24829000",
      "15342002",
      "14311001",
      "13470001",
      "275549008"
    ]
  },
  {
    "id": "uterine_cancer",
    "snomed": [
      "371973000",
      "10708511000119108",
      "208041000119100",
      "184781000119102",
      "107791000119107",
      "107771000119106",
      "107751000119102",
      "785807007",
      "778066006",
      "773284000",
      "773283006",
      "770559003",
      "766930002",
      "766758001",
      "766248004",
      "766247009",
      "765740002",
      "764952009",
      "764951002",
      "764847000",
      "764737005",
      "763771009",
      "763409006",
      "763408003",
      "763064007",
      "763063001",
      "762458004",
      "733834006",
      "733360000",
      "733359005",
      "732201008",
      "723077004",
      "722682001",
      "722681008",
      "722680009",
      "722679006",
      "702369008",
      "699358009",
      "699357004",
      "699356008",
      "449073009",
      "449054005",
      "447390000",
      "447389009",
      "447266004",
      "446022000",
      "423973006",
      "385478001",
      "372100004",
      "372099007",
      "372098004",
      "372097009",
      "372024009",
      "371972005",
      "371971003",
      "369592007",
      "369591000",
      "369588000",
      "369579009",
      "369572000",
      "369571007",
      "369568004",
      "369557004",
      "369556008",
      "369553000",
      "369499000",
      "369498008",
      "369497003",
      "369496007",
      "369495006",
      "369494005",
      "369493004",
      "363354003",
      "314970000",
      "309245001",
      "285432005",
      "254888007",
      "254887002",
      "254886006",
      "254878006",
      "254877001",
      "188195000",
      "188193007",
      "188192002",
      "188191009",
      "188190005",
      "188189001",
      "188184006",
      "188183000",
      "188180002",
      "188177003",
      "188176007",
      "123845008",
      "123842006",
      "109882003",
      "109880006",
      "109879008",
      "93915004",
      "93844001",
      "93789008",
      "93781006",
      "93779009",
      "428944005",
      "124361000119101",
      "428941002",
      "143341000119101",
      "429089000"
    ]
  },
  {
    "id": "von_hippel_lindau_syndrome",
    "snomed": [
      "46659004"
    ]
  },
  {
    "id": "wilsons_disease",
    "snomed": [
      "88518009",
      "724766009"
    ]
  }
]
;
